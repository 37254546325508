export default [
  {
    path: "/produtos/netflix",
    name: "netflix",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Netflix - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "netflix" */
        "../../pages/home/Netflix.vue"
      )
  },
  {
    path: "/claro-clube/cadastro",
    name: "claro-clube-registration",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Claro Clube Cadastro - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "claro-clube-registration" */
        "../../pages/home/ClaroClubeRegistration.vue"
      )
  },
  {
    path: "/claro-clube/consulte-seu-saldo",
    name: "claro-clube-check-balance",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Claro Clube Consulte Seu Saldo - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "claro-clube-check-balance" */
        "../../pages/home/ClaroClubeCheckBalance.vue"
      )
  },
  {
    path: "/claro-clube/resgate",
    name: "claro-clube-resgate",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Claro Clube Resgate - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "claro-clube-resgate" */
        "../../pages/products-and-sales/ClaroClubeResgate.vue"
      )
  },
  {
    path: "/claro-clube/parceiros",
    name: "claro-clube-partners",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "produtos-e-vendas",
      title: "Claro Clube Resgate - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "claro-clube-resgate" */
        "../../pages/products-and-sales/ClaroClubePartners.vue"
      )
  },
  {
    path: "/atendimento/ativacao-chip-combo-multi",
    name: "ativacao-chip-combo-multi",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Produtos e Vendas",
      title: "Ativação de Chip Combo Multi - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "ativacao-chip-combo-multi" */
        "../../pages/products-and-sales/ChipActivation.vue"
      )
  },
  {
    path: "/atendimento/antivirus",
    name: "antivirus",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Produtos e Vendas",
      title: "Antivirus - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "antivirus-migration" */
        "../../pages/products-and-sales/Antivirus.vue"
      )
  },
  {
    path: "/agendamento-de-gravacao",
    name: "agendamento-de-gravacao",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Produtos e Vendas",
      title: "Agendamento de Gravação - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "gravacao-remota-migration" */
        "../../pages/products-and-sales/RemoteRecording.vue"
      )
  },
  {
    path: "/minhas-assinaturas",
    name: "my-signatures",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Produtos e Vendas",
      title: "Minhas Assinaturas - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "my-signatures" */
        "../../pages/products-and-sales/MySignatures.vue"
      )
  },
  {
    path: "/meu-plano/upgrade-banda-larga",
    name: "upgrade-banda-larga",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Produtos e Vendas",
      title: "Upgrade Banda Larga - Minha Claro Residencial",
      hasBanner: true
    },
    component: () =>
      import(
        /* webpackChunkName: "swap-broadband" */
        "../../pages/products-and-sales/SwapBroadband.vue"
      )
  },
  {
    path: "/claro-clube",
    name: "claro-clube",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Claro Clube - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "claro-clube" */
        "../../pages/products-and-sales/ClaroClube.vue"
      )
  },
  {
    path: "/vantagens",
    name: "benefits",
    meta: {
      requiresAuth: true,
      squad: "Produtos e Vendas",
      title: "Vantagens - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: () =>
      import(
        /* webpackChunkName: "benefits" */
        "../../pages/home/Benefits.vue"
      )
  },
];
