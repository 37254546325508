<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user"]),
    isShow() {
      return (
        this.user &&
        this.user.selectedContract &&
        this.user.selectedContract.status.isInactive
      );
    }
  }
};
</script>

<template>
  <div class="mdn-Container mdn-u-paddingTop--xs" v-if="isShow">
    <div class="mdn-Alert mdn-Alert--danger mdn-Alert--light mdn-isOpen">
      <div class="mdn-Tag mdn-Tag--feedback">
        <div class="mdn-Alert-content">
          <em class="mdn-Tag-icon mdn-Icon-alerta mdn-Icon--sm"></em>
          <p>
            <strong class="mdn-u-textBold">Atenção:</strong> Este contrato foi
            cancelado, o que pode resultar na indisponibilidade de alguns
            serviços e funcionalidades.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
