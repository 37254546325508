import Utils from "@minhaclarors/utils";

const WCP_HOME = process.env.VUE_APP_WCP_HOME;
const CTV_HOME = process.env.VUE_APP_CTV_HOME;

export const customerTypes = {
  CTV: "claro-tv",
  NET: "net"
};

function PartnerOperations(params) {
  const { contractNumber, operatorCode, idmAccessToken, customerType } = params;

  const authParamsEncoded = Utils.b64EncodeUnicode(
    JSON.stringify({
      c: contractNumber,
      o: operatorCode,
      a: idmAccessToken
    })
  );

  function addSelectedContractQueryParameter(href) {
    let url = new URL(href);

    if (
      url.host.includes("mctv") ||
      url.host.includes("clarotvdev")
    ) {
      const separator = href.includes("?") ? "&" : "?";
      return `${href}${separator}selected-contract=${authParamsEncoded}`;
    }

    const queryStrings = new URLSearchParams(url.search);
    queryStrings.append("selected-contract", authParamsEncoded);

    url.search = queryStrings.toString();

    return url.toString();
  }

  function redirect(href) {
    let url = href;

    if (!href.includes(window.location.origin)) {
      url = addSelectedContractQueryParameter(href);
    }

    location.href = url;
  }

  function redirectToHome() {
    customerType === customerTypes.CTV
      ? redirect(CTV_HOME)
      : redirect(WCP_HOME);
  }

  return {
    redirect,
    redirectToHome,
    addSelectedContractQueryParameter
  };
}

export default PartnerOperations;
