import JSCookie from "js-cookie";
import contractConector from "@minhaclarors/contract-connector";
import { backendUniqueAuthService } from "../../services/backendUniqueAuthService";
import DT from "../../modules/tracking/dynatrace";
import {
  SET_AUTHENTICATED_ERROR,
  SET_IDM_ACCESS_TOKEN,
  SET_IS_AUTHENTICATED,
  SET_LOGOFF,
  SET_DOCUMENT,
  SET_CLAROID,
  SET_AATOKEN,
  SET_REQUESTING_AATOKEN,
  SET_CONTRACT_LIST,
  SET_IS_LOADING,
  SET_SSO_PROVIDER,
  SET_ACCESS_TOKEN,
} from "./mutationTypes";
import { createCacheStorage } from "../../utils/cache";

export default {
  [SET_IDM_ACCESS_TOKEN]: (state, idmAccessToken) => {
    state.idmAccessToken = idmAccessToken;

    window.localStorage.setItem("idmAccessToken", idmAccessToken);

    JSCookie.set("IDM_ACCESS_TOKEN", idmAccessToken);

    window.McrProps.IDM_ACCESS_TOKEN = idmAccessToken;
    contractConector.setValue({
      ...contractConector.getValue(),
      aaToken: idmAccessToken
    });
  },
  [SET_IS_AUTHENTICATED]: (state, newValue) => {
    state.isAuthenticated = newValue;
  },

  [SET_LOGOFF]: state => {
    state.idmAccessToken = null;
    state.isAuthenticated = false;
    window.localStorage.removeItem("selectedContract");
    window.localStorage.removeItem("idmAccessToken");
    window.localStorage.removeItem("ssoAccessToken");
    JSCookie.remove("IDM_ACCESS_TOKEN");
    JSCookie.remove("SSO_ACCESS_TOKEN");
    JSCookie.remove("Claro_ID");
    JSCookie.remove("CD_Contrato");
    JSCookie.remove("CD_Operadora");
    JSCookie.remove("mcr.cidade");
    JSCookie.remove("mcr.estado");
    JSCookie.remove("PJ_CONTRACT_NUMBER");
    createCacheStorage().clear();
    DT.endSession();
    backendUniqueAuthService().clearToken();
  },

  [SET_AUTHENTICATED_ERROR]: state => {
    state.isAuthenticated = false;
  },

  [SET_DOCUMENT]: (state, document) => {
    state.document = document;
  },

  [SET_CLAROID]: (state, id) => {
    state.claroId = id;

    // Set Qualtrics Claro ID EmbedData Cookie
    JSCookie.set("Claro_ID", id)
  },

  [SET_AATOKEN]: (state, data) => {
    state.aaToken[data.contractNumber] = data.token;
  },

  [SET_REQUESTING_AATOKEN]: (state, requesting) => {
    state.requestingAAToken = requesting;
  },

  [SET_CONTRACT_LIST]: (state, contracts) => {
    state.contractList = contracts;
  },

  [SET_IS_LOADING]: (state, status) => {
    state.isLoading = status;
  },
  
  [SET_SSO_PROVIDER]: (state, provider) => {
    state.ssoProvider = provider;
  },

  [SET_ACCESS_TOKEN]: (state, token) => {
    state.accessToken = token;
    window.localStorage.setItem("ssoAccessToken", token);
    JSCookie.set("SSO_ACCESS_TOKEN", token);
  }
};
