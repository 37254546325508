import { jsonUtil } from "./jsonUtil";

/**
 * @typedef Options
 * @type {object}
 * @property {string} id
 * @property {function(ResponseInterceptor): void} [onLoad]
 */

/**
 * @typedef ResponseInterceptor
 * @type {object}
 * @property {string} url - URL da resposta
 * @property {number} statusCode - Status da resposta
 * @property {object} [responseText] - Texto da resposta (se for um JSON válido) ou um objeto vazio {}
 */

/**
 * @param {Options} options
 * @returns {XMLHttpRequest}
 */
function interceptorXHR(options) {
	const open = XMLHttpRequest.prototype.open;

	window.XMLHttpRequest.prototype.open = function() {
		if (options.onLoad) {
			this.addEventListener("loadend", function(event) {
				options.onLoad({
					url: this.responseURL,
					statusCode: this.status,
          responseText: jsonUtil.parsedString(event.target.responseText)
				});
			});
		}

		return open.apply(this, arguments);
	}
}

/**
 * @param {Options} options
 * @returns {ReturnType<typeof window.fetch>}
 */
function interceptorFetch(options) {
	const originalFetch = window.fetch;

	window.fetch = function() {
		return originalFetch.apply(this, arguments)
			.then(response => {
				if (options.onLoad) {
					options.onLoad({
						url: response.url,
						statusCode: response.status
					});
				}

				return response;
			});
	}
}

/**
 * @param {Options} options
 */
export function createNetworkRequestsInterceptor(options) {

	if (process.env.VUE_APP_MODE === "local") {
		window.httpInterceptors = window.httpInterceptors || [];

		if (window.httpInterceptors.includes(options.id)) return;

		window.httpInterceptors.push(options.id);
	}

	interceptorXHR(options);
	interceptorFetch(options);
}
