const id = "vue3-libs";

export const vue3Libraries = {
  async load() {
    console.log("[LIB][VUE3] - Loading");

    const script = document.createElement("script");
    script.src = `/${id}.js`;
    // script.src = `http://localhost:9000/bundle.js`;
    script.dataset.libId = id;
    window.document.body.appendChild(script);

    await new Promise(
      resolve =>
        (script.onload = () => {
          console.log(`[LIB][VUE3] - Loaded`);
          resolve();
        })
    );
  },
  async unload() {
    const elements =
      window.document.querySelectorAll(`[data-lib-id="${id}"]`) || [];

    for (const element of elements) {
      element.remove();
    }

    window.Vue = undefined;
    window.VueDemi = undefined;
    window.Pinia = undefined;
    window.VueUse = undefined;

    console.log("[LIB][VUE3] - Unloaded");

    return Promise.resolve();
  }
};
