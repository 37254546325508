<template>
  <div
    class="mdn-Modal mdn-Modal--sm mcr-ModalRedirect"
    :class="{ 'mdn-isOpen': open }"
    @click.self="() => close()"
  >
    <div class="mdn-Modal-content">
      <div class="mdn-Modal-header">
        <h1 class="mdn-Heading mdn-Heading--md">
          {{ texts.title }}
        </h1>
        <button
          class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary"
          aria-label="Close modal"
          @click="() => close()"
        >
          <i class="mdn-Icon-fechar mdn-Icon--md"></i>
        </button>
      </div>
      <div class="mdn-Divider mdn-Divider--default"></div>
      <div class="mdn-Modal-body">
        <!-- this content is just example to be show - Start -->
        <p class="mdn-Text mdn-Text--body">{{ texts.text }}</p>
        <div class="mdn-CopyContainer mdn-u-marginTop--sm" v-if="enviroment === 'DEFAULT'">
          <div class="mdn-CopyContainer-content">
            <p class="mdn-CopyContainer-content-toCopy mdn-Heading mdn-Heading--xxs">
              {{ redirectUrl }}
            </p>
          </div>
        </div>
        <!-- this content is just example to be show - End -->
      </div>
      <div class="mdn-Modal-footer">
        <div class="mdn-Modal-footer-end">
          <button
            class="mdn-Button mdn-Button--secondary"
            @click="() => close()"
          >
            Agora não
          </button>
          <a
            class="mdn-Button mdn-Button--primary"
            :href="redirectUrl"
            @click.prevent="handleClickConfirm"
            >Acessar o site</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtm from '../../modules/tracking/gtm';

const environmentsSubdomains = {
  MCM: ["minhaclaro", "minhaclaroescondida"],
  MCTV: ["mctv", "mctv3"]
};

const textMapper = {
  MCM: {
    title: "Você é um Cliente Combo Multi",
    text:
      "Para acessar esta opção você será redirecionado ao site Minha Claro Móvel."
  },
  MCTV: {
    title: "Você é um Cliente Combo Multi",
    text:
      "Para acessar esta opção você será redirecionado ao site Minha Claro Tv."
  },
  DEFAULT: {
    title: "Redirecionamento",
    text: "Para acessar esta opção você será redirecionado ao site abaixo:"
  }
};

export default {
  data() {
    return {
      open: false,
      redirectUrl: ""
    };
  },
  computed: {
    texts(vm) {
      return textMapper[vm.enviroment];
    },
    enviroment(vm) {
      let subDomain;

      try {
        subDomain = new URL(vm.redirectUrl).hostname.split(".")[0];
      } catch (e) {
        return "DEFAULT";
      }

      let environment;

      for (let subdomain in environmentsSubdomains) {
        if (environmentsSubdomains[subdomain].includes(subDomain)) {
          environment = subdomain;
          break;
        }
      }

      if (!environment) environment = "DEFAULT";

      return environment;
    }
  },
  methods: {
    handleClickConfirm() {
      gtm.trackSendEvent({
        eventCategory: "minha-claro-res:redirecionamento",
        eventAction: "clique:acessar",
        eventLabel: this.redirectUrl
      });

      this.open = false;
      window.open(this.redirectUrl, "_blank");
    },
    close() {
      gtm.trackSendEvent({
        eventCategory: "minha-claro-res:redirecionamento",
        eventAction: "clique:fechar",
        eventLabel: this.redirectUrl
      });

      this.open = false;
    },
    show(params) {
      gtm.trackSendEvent({
        eventCategory: "minha-claro-res:redirecionamento",
        eventAction: "abriu",
        eventLabel: this.redirectUrl
      });

      this.redirectUrl = params.redirectUrl;
      this.open = true;
    }
  },
  beforeMount() {
    this.EventBus.$on("show", params => {
      this.show(params);
    });
  }
};
</script>

<style lang="scss" scoped>
.mcr-ModalRedirect {
  z-index: 1000001; // Necessário para ficar acima do handtalk
}

@media (max-width: 768px) {
  .mcr-ModalRedirect {
    .mdn-Modal-content {
      width: 100vw;
      max-width: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      align-self: flex-end;
    }

    .mdn-Modal-footer-end {
      gap: var(--spacing-inline-xs);
    }
  }
}
</style>
