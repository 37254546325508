import gwAppKeys from "./gw";

export default {
  IDMDOMAIN: "https://idmlabft.virtua.com.br",
  GWDOMAIN: "https://dev-gw.claro.com.br",
  CLAROTVDOMAIN: "https://clarotvdev.clarobrasil.mobi",
  PROVIDERDOMAIN: "https://dev-gw.claro.com.br",
  MNETAPIDOMAIN: "https://minhanet-sit.dcing.corp",
  CLIENTID: "MinhaClaroSTAGING_QA",
  GEROID: "GERO-NET1250875",
  GEROINITDOMAIN: ".claro.com.br",
  GERODOMAIN: "https://person.cellit.io",
  GTMID: "GTM-PRRCNW3",
  SITEURL: "https://claro.com.br",
  GWAPPKEYS: gwAppKeys,
};
