import axios from "axios";

export function functionalityPermissionService() {
  const $http = axios.create({
    baseURL: window.McrProps.APIGEE.DOMAIN,
    headers: {
      "x-client-type": window.McrProps.APIGEE.TYPE,
      "x-client-key": window.McrProps.APIGEE.KEYS.MCR.PUBLIC
    }
  });

  return {
    /**
     * @param {string} functionality - Functionality to check
     * @returns {Promise<boolean>}
     */
    async getFunctionality(functionality) {
      try {
        const response = await $http.get(
          `/residential/v1/functionality-permission/permission/${functionality}`
        );

        return response.data.canAccess;
      } catch (error) {
        throw new Error(error);
      }
    }
  };
}
