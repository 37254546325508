export default {
  footerMenuList: [],
  user: null,
  pageState: {
    WAITING: true,
    READY: false,
    ERROR: false,
    UNAVAILABLE: false
  },
  outage: null,
  contractSelectionNotification: null,
  loadingLibrary: false
};
