<template>
  <main class="mdn-Container">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "default-layout"
};
</script>

<style src="@minhaclarors/header/dist/mcr-header.css" />
