const SET_FOOTER_MENU_LIST = "SET_FOOTER_MENU_LIST";
const SET_USER_AUTHENTICATED = "SET_USER_AUTHENTICATED";
const SET_PAGE_STATE = "SET_PAGE_STATE";
const SET_OUTAGE = "SET_OUTAGE";
const SET_CLAROCLUBE_SITUATION = "SET_CLAROCLUBE_SITUATION";
const SET_CONTRACT_SELECTION_NOTIFICATION = "SET_CONTRACT_SELECTION_NOTIFICATION";
const SET_LOADING_LIBRARY = "SET_LOADING_LIBRARY";
const SET_FUNCTIONALITIES = "SET_FUNCTIONALITIES";

export {
  SET_FOOTER_MENU_LIST,
  SET_USER_AUTHENTICATED,
  SET_PAGE_STATE,
  SET_OUTAGE,
  SET_CLAROCLUBE_SITUATION,
  SET_CONTRACT_SELECTION_NOTIFICATION,
  SET_LOADING_LIBRARY,
  SET_FUNCTIONALITIES
};
