
import parseAuthData from "./parseAuthData";

const getParsedJsonFromCookie = (cookieName, getCookie, b64Decode) => {
  const cookie = getCookie(cookieName);

  if (!cookie) return false;

  const decodedJSONString = b64Decode(cookie);
  const parsedJson = JSON.parse(decodedJSONString);

  return parsedJson;
};

export default (getCookie, b64Decode) => {
  const COOKIE_TYPES = {
    WEB: "WebAuth",
    APP: "AppAuth",
    APP_UNIFIED: "AppUnifiedAuth"
  };

  const appAuthData = getParsedJsonFromCookie(
    COOKIE_TYPES.APP,
    getCookie,
    b64Decode
  );

  if (appAuthData) {
    console.info("Authenticated by Cookie AppAuthData");
    return {
      isAuthByApp: true,
      authData: parseAuthData(appAuthData)
    };
  }

  const appUnifiedAuthData = getCookie(COOKIE_TYPES.APP_UNIFIED);

  if (appUnifiedAuthData) {
    console.info("Authenticated by Cookie AppUnifiedAuthData");
    return {
      isAuthByApp: true,
      authData: parseAuthData({
        aa: appUnifiedAuthData
      })
    };
  }

  const webAuthData = getParsedJsonFromCookie(
    COOKIE_TYPES.WEB,
    getCookie,
    b64Decode
  );

  if (webAuthData) {
    console.info("Authenticated by Cookie WebAuthData");
    return {
      isAuthByApp: false,
      authData: parseAuthData(webAuthData)
    };
  }

  return;
};