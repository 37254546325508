<template>
  <fragment>
    <header class="mdn-Header mdn-Header--simple">
      <div class="mdn-Container">
        <div class="mdn-Header--simple-content">
          <a href="https://claro.com.br">
            <img
              class="mdn-logoSvg"
              src="https://mondrian.claro.com.br/brands/horizontal/inverse/minha-claro.svg"
              alt="Logo da Minha Claro"
            />
          </a>
        </div>
      </div>
    </header>
    <main class="mdn-Container">
      <slot></slot>
    </main>
    <mdn-footer-simple></mdn-footer-simple>
  </fragment>
</template>

<script>
import mdnFooterSimple from "../components/MdnFooterSimple";

export default {
  name: "cross-layout",
  components: {
    mdnFooterSimple
  }
};
</script>
