import local from "./envs/local";
import staging from "./envs/staging";
import homolog from "./envs/homolog";
import production from "./envs/production";

let currentEnv = "local";

const Environments = {
  production: production,
  homolog: homolog,
  staging: staging,
  local: local
};

export default {
  getEnvironment: () => {
    return Environments[currentEnv];
  },
  setCurrentEnv: envName => {
    currentEnv = envName;
  }
};
