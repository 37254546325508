<template>
  <div
    class="wrapper"
    :class="{
      'wrapper--isFull': isFull
    }"
    :style="`--header-height: ${getHeaderHeight()}px`"
    v-if="loadingLibrary"
  >
    <div class="wrapper__content">
      <div class="mdn-Spinner">
        <i class="mdn-Icon-claro mdn-Icon--lg"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["loadingLibrary"])
  },
  data() {
    return {
      isFull: false
    };
  },
  methods: {
    getHeaderHeight() {
      const header = window.document.getElementById("main-header");
      return header ? header.clientHeight : 0;
    },
    updateHeight() {
      this.isFull = window.pageYOffset > 0;
    }
  },
  mounted() {
    this.updateHeight();
    window.document.addEventListener("scroll", this.updateHeight);
  },
  beforeDestroy() {
    window.document.removeEventListener("scroll", this.updateHeight);
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  height: calc(100vh - var(--header-height));
  width: 100vw;
  top: var(--header-height);
  left: 0;
  background-color: var(--color-neutral-lightest);
  z-index: 99999;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--header-height);
  }

  &--isFull {
    height: 100vh;
    top: 0;
  }

  &__content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .mdn-Spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
  }
}
</style>
