import { coreLibraries } from "./core";
import { vue3Libraries } from "./vue3";
import { reactLibraries } from "./react";

export const libraries = {
  core: coreLibraries,
  vue3: vue3Libraries,
  routerEnterAdapter(to, from) {
    if (to.meta && to.meta.isVue3) {
      if (from.meta && from.meta.isVue3) vue3Libraries.unload();
      return vue3Libraries.load();
    }
    if (to.meta && to.meta.isReact) return reactLibraries.load();
    return Promise.resolve();
  },
  routerLeaveAdapter(to) {
    if (to.meta && !to.meta.isVue3) return vue3Libraries.unload();
    if (to.meta && !to.meta.isReact) return reactLibraries.unload();
  }
};
