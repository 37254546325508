import Microfrontend from "../../pages/MicroFrontend.vue";

export default [
  {
    path: "/financeiro/endereco-de-cobranca",
    name: "billing-address",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Endereço de Cobrança - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-billing-address" */
        "../../pages/financial/BillingAddress.vue"
      )
  },
  {
    path: "/totem/hub-pagamentos",
    name: "payment-page",
    meta: {
      requiresAuth: true,
      isTotem: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Pagamento - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentHub" */
        "../../pages/financial/PaymentHub.vue"
      )
  },
  {
    path: "/financeiro/historico-ppv",
    name: "ppv-history-page",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: false,
      squad: "Financeiro",
      title: "Histórico de pay-per-view - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-ppv-history" */
        "../../pages/financial/PpvHistory.vue"
      )
  },
  {
    path: "/financeiro/historico-now",
    name: "now-history",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Histórico Now - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-now-history" */
        "../../pages/financial/NowHistory.vue"
      )
  },
  {
    path: "/financeiro/comprovante-de-compras",
    name: "purchase-receipt",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Comprovante de Compras - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-purchase-receipt" */
        "../../pages/financial/PurchaseReceipt.vue"
      )
  },
  {
    path: "/financeiro/data-de-vencimento",
    name: "expiration-date",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Data de Vencimento - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-expiration-date" */
        "../../pages/financial/ExpirationDate.vue"
      )
  },
  {
    path: "/financeiro/fatura-digital",
    name: "digital-invoice",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: false,
      squad: "Financeiro",
      title: "Fatura Digital - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-digital-invoice" */
        "../../pages/financial/DigitalInvoice.vue"
      )
  },
  {
    path: "/financeiro/declaracao-de-quitacao",
    name: "discharge-statement",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      isEnabledForNet: true,
      isEnabledForCtv: false,
      squad: "Financeiro",
      title: "Declaração de Quitação - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-discharge-statement" */
        "../../pages/financial/DischargeStatement.vue"
      )
  },
  {
    path: "/financeiro/contestacao",
    name: "dispute-invoice",
    meta: {
      requiresAuth: true,
      isEnabledForNet: true,
      isEnabledForCtv: false,
      squad: "Financeiro",
      title: "Contestação - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "dispute-invoice" */
        "../../pages/financial/DisputeInvoice.vue"
      )
  },
  {
    path: "/validador-boleto",
    name: "billet-validador",
    meta: {
      isPublic: true,
      isCross: true,
      squad: "Financeiro",
      title: "Validador de Boleto - Minha Claro"
    },
    component: () =>
      import(
        /* webpackChunkName: "billet-validator" */
        "../../pages/financial/BilletValidator.vue"
      )
  },
  {
    path: "/fatura-facil",
    name: "easy-invoice",
    meta: {
      isPublic: true,
      squad: "Financeiro",
      title: "Fatura Fácil - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "easy-invoice" */
        "../../pages/financial/EasyInvoice.vue"
      )
  },
  {
    path: "/totem/faturas",
    name: "totem-invoices",
    meta: {
      requiresAuth: true,
      isTotem: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Faturas - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "totem-invoices" */
        "../../pages/financial/TotemInvoice.vue"
      )
  },
  {
    path: "/financeiro/entenda-sua-fatura",
    name: "invoice-steps",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Entenda Sua Fatura - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "invoice-steps"
    }
  },
  {
    path: "/financeiro/promessa-pagamento",
    name: "payment-promise",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Promessa de Pagamento - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "payment-promise"
    }
  },
  {
    path: "/financeiro/fatura-interativa",
    name: "interactive-invoice",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Fatura Interativa - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "interactive-invoice"
    }
  },
  {
    path: "/financeiro/forma-de-pagamento",
    name: "payment-methods",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Forma de Pagamento - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "payment-methods"
    }
  },
  {
    path: "/financeiro/formas-de-recebimento",
    name: "delivery-methods",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Formas de Recebimento - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "delivery-methods"
    }
  },
  {
    path: "/financeiro/negociacao-de-faturas",
    name: "negotiation",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Negociação de Faturas - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: false
    },
    component: Microfrontend,
    props: {
      pageId: "negotiation"
    }
  },
  {
    path: "/financeiro/comparador-de-faturas",
    name: "invoices-comparator",
    meta: {
      requiresAuth: true,
      squad: "Financeiro",
      title: "Comparador de Faturas - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "invoices-comparator"
    }
  },
  {
    path: "/financeiro/hub-pagamentos",
    name: "payment-page",
    meta: {
      requiresAuth: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Financeiro",
      title: "Pagamento - Minha Claro Residencial"
    },
    component: Microfrontend,
    props: {
      pageId: "mfe-payments"
    }
  }
];
