import axios from "axios";
import axiosRetry from "axios-retry";

/**
 * @typedef Options
 * @type {object}
 * @property {string} operatorCode - Código da operadora
 * @property {string} contractNumber - Número do contrato
 * @property {string} document - Número do documento
 * @property {string} idmToken - Token do IDM
 */

/** @param {Options} options */
const createApigeeHttp = options => {
  const {
    idmToken,
    document,
    operatorCode,
    contractNumber,
  } = options;

  const client = axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/residential/v1/customer-contracts/contracts`,
    headers: {
      "client-id": "SITERES",
      "authorization": `Bearer ${idmToken}`,
      "x-client-key": window.McrProps.APIGEE.KEYS.MCR.LOGGED,
      "x-querystring": `operatorcode=${operatorCode}&contractnumber=${contractNumber}&document=${document}`
    }
  });

  axiosRetry(client, {
    retries: 3,
    retryDelay: retryCount => retryCount * 1000,
    retryCondition: error => error.response.status >= 500
  });

  return client;
};

/** @param {Options} options */
export function createCustomerContractsService(options) {
  const $http = createApigeeHttp(options);

  return {
    /**
     * @returns {Promise<void>}
     * @description Envia uma notificação de seleção de contrato para o Portal Admin
     */
    async sendSelectContractNotification() {
      try {
        $http.put("/notifications");
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * @param {string} token
     * @description Retorna os detalhes do contrato
     */
    async getContractDetails(token) {
      try {
        if (token) {
          return $http.get("/details", {
            headers: {
              authorization: `Bearer ${token}`
            }
          });
        }

        return $http.get("/details");
      } catch (error) {
        console.log(error);
      }
    }
  };
}
