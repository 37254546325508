<template>
  <div>
    <mcr-autodiagnostic-wrapper />
    <micro-frontend pageId="mfe-dashboard" />
    <micro-frontend pageId="mfe-campaigns" />
  </div>
</template>

<script>
import McrAutodiagnosticWrapper from "../../components/McrAutodiagnostic.vue";
import MicroFrontend from "../MicroFrontend.vue";

export default {
  name: "dashboard",
  components: {
    McrAutodiagnosticWrapper,
    MicroFrontend
  }
};
</script>