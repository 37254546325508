import utils from "@minhaclarors/utils";
import jsCookie from "js-cookie";
import jwtSimple from "jwt-simple";

import environments from "./environments";
import UserContract from "../user/contract";

import contractConnector from "@minhaclarors/contract-connector";

const clarotvOrigin = "clarotv";
const minhanetOrigin = "minhanet";
const appOrigin = "minhanetapp";
const acceptedOrigins = [minhanetOrigin, clarotvOrigin, appOrigin];

const defaultEnv = "production";

/**
 * This user-identificator module is used to global user identificator across all portals.
 */
export default {
  init,
  initEnvironents,
  getContract,
  getPartnerContract,
  prepareHeader,
  prepareUIToAppAccess,
  onHeaderLoaded,
  onChoosenContract,
  requestContractSelection
};

function init(config) {
  let contract = null;

  if (!config.idmAccessToken) {
    throw Error("Token expired or invalid to start client identificator!");
  }

  environments.setCurrentEnv(config.env || defaultEnv);
  contract = getContract();

  initEnvironents(environments);

  prepareHeader(contract, config);
}

/**
 * prepare Header
 * @param {*} contract
 * @param {*} headerProps
 */
function prepareHeader(contract, config) {
  config.headerProps.selectedContract = contract;
  config.headerProps.onHeaderLoaded = params => onHeaderLoaded(params, config);
  config.headerProps.choosenContract = params =>
    onChoosenContract(params, config);
  config.headerProps.idmAccessToken = config.idmAccessToken;
  config.headerProps.gwDomain = window.McrProps.GWDOMAIN;
}

/**
 * Exec func on header is finished to loaded
 * @param {*} params
 */
function onHeaderLoaded(params, config) {
  (async () => {
    const selectedContractSession = getContract();
    let userState = params.userState;
    let contractList = params.contractList;
    let selectedContract = params.selectedContract || selectedContractSession ? contractList.find(c => c.contractNumber === selectedContractSession.contractNumber) : undefined;
    let documentNumber = params.documentNumber;

    let clientNode = null;

    if (selectedContract) {
      clientNode = selectedContract.additionalData.node;

      if (config.outageCheck) {
        await config.outageCheck({ clientNode, selectedContract });
      }
    }

    if (
      !selectedContract &&
      contractList.length > 1 &&
      location.href.indexOf(config.contractSelectionPath) === -1
    ) {
      requestContractSelection(
        config.contractSelectionPath,
        config.originUrl,
        config.router
      );

      if (!config.router) return;
    }

    selectedContract = selectedContract
      ? {
          ...selectedContract,
          docCpf: documentNumber,
          docCnpj: documentNumber
        }
      : null;

    UserContract.setLoggedUser({
      unifiedPortal: config.unifiedPortal,
      userState: userState,
      contractList: contractList,
      documentNumber: documentNumber,
      logout: config.logoutFunc,
      selectedContract: selectedContract
    });

    UserContract.setContract(selectedContract);

    if (config.onAppReady) config.onAppReady(params);
  })();
}

/**
 * Exec func on contract is choosen
 * @param {*} params
 */
async function onChoosenContract(params, config) {
  let selectedContract = params.selectedContract;
  let stopExec = false;

  if (config.beforeChangeContract) {
    stopExec = config.beforeChangeContract(params);

    if (stopExec) return;
  }

  console.log(
    "selected contract with document: " +
      window.McrProps.STORE.state.Header.documentNumber
  );

  selectedContract = {
    ...selectedContract,
    docCpf:
      params.documentNumber ||
      window.McrProps.STORE.state.Header.documentNumber,
    docCnpj:
      params.documentNumber || window.McrProps.STORE.state.Header.documentNumber
  };

  contractConnector.setValue({
    ...contractConnector.getValue(),
    document: params.documentNumber || window.McrProps.STORE.state.Header.documentNumber,
    operatorCode: selectedContract.operatorCode,
    contractNumber: selectedContract.contractNumber,
  });

  window.localStorage.setItem(
    "selectedContract",
    JSON.stringify({
      contractNumber: selectedContract.contractNumber,
      operatorCode: selectedContract.operatorCode
    })
  );

  UserContract.setLoggedUser({
    ...window.$loggedUser,
    selectedContract: { ...selectedContract }
  });

  UserContract.setContract(selectedContract);

  // Set Qualtrics EmbedDatas Cookies
  jsCookie.set('CD_Contrato', selectedContract.contractNumber)
  jsCookie.set('CD_Operadora', selectedContract.operatorCode)
  jsCookie.set('mcr.cidade', selectedContract.addresses[0].city.name)
  jsCookie.set('mcr.estado', selectedContract.addresses[0].stateOrProvince)

  if (config.afterChangeContract) {
    config.afterChangeContract(params);
  }
}

/**
 * Get Contract from some source partner or browser storage.
 */
function getContract() {
  let selectedContract = null;
  let hasPartnerSelectedContract = getPartnerContract();
  let hasSelectedContract =
    hasPartnerSelectedContract ||
    window.localStorage.getItem("selectedContract");

  if (hasSelectedContract) {
    hasSelectedContract = JSON.parse(hasSelectedContract);

    if (
      !hasPartnerSelectedContract ||
      (hasPartnerSelectedContract &&
        acceptedOrigins.indexOf(hasSelectedContract.origin) !== -1)
    ) {
      selectedContract = hasSelectedContract;
    }
  }

  return selectedContract;
}

/**
 * Get Contract from partner requests (App or ClaroTV)
 */
function getPartnerContract() {
  let url = new URL(location.href);
  let params = url.searchParams.get("partner-selected-contract");
  let origin = url.searchParams.get("origin");
  let sessionCookie = null;
  let fromApp = window.localStorage.getItem("from-app") ? true : false;

  if (fromApp || origin === appOrigin) {
    prepareUIToAppAccess(fromApp);
  }

  if (origin === appOrigin && location.origin.indexOf(minhanetOrigin) !== -1) {
    sessionCookie = jsCookie.get("sessionCookie");

    if (sessionCookie) {
      sessionCookie = jwtSimple.decode(sessionCookie, "", true);

      params = JSON.stringify({
        contractNumber: sessionCookie.contrato,
        operatorCode: sessionCookie.codigoOperadora,
        origin: origin
      });

      return params;
    }
  }

  if (params) {
    return utils.b64DecodeUnicode(params);
  }

  return null;
}

/**
 * Hide Header and Footer for App
 */
function prepareUIToAppAccess(fromApp) {
  let mainHeader = document.querySelector("#main-header");
  let mdnFooter = document.querySelector(".mdn-Footer");
  let mcrFooter = document.querySelector(".mcr-footer");
  let mcrMenuAccessibility = document.querySelector(".mcr-menu-accessibility");
  let mcrShortcutMenu = document.querySelector(".mcr-shortcut-menu");
  let mcrAppBar = document.querySelector(".mcr-AppBar");

  if (!fromApp) {
    window.sessionStorage.setItem("from-app", appOrigin);
  }

  if (mainHeader) mainHeader.remove();
  if (mdnFooter) mdnFooter.remove();
  if (mcrFooter) mcrFooter.remove();
  if (mcrMenuAccessibility) mcrMenuAccessibility.remove();
  if (mcrShortcutMenu) mcrShortcutMenu.remove();
  if (mcrAppBar) mcrAppBar.remove();

  document.body.classList.add("from-app");
}

/**
 * Start environments vars
 */
function initEnvironents(env) {
  let envs = env.getEnvironment();

  if (envs) {
    window.Environment = envs;
    window.McrProps = { ...window.McrProps, ...envs };
    window.Environment.gwAppKeys = window.Environment.GWAPPKEYS;
  }
}

function requestContractSelection(contractSelectionPath, originUrl, router) {
  window.sessionStorage.setItem("originUrl", originUrl);

  if (router) {
    router.replace({
      path: contractSelectionPath
    });

    return;
  }

  location.href = contractSelectionPath;
}