import { backendUniqueAuthService } from '../../services/backendUniqueAuthService';
import * as AuthMutations from "../../store/auth/mutationTypes";

/**
 * @typedef BackendUniqueAuthOptions
 * @type {object}
 * @property {import("vuex").Store} store
 * @property {boolean} isNavigation
 */

/** @param {BackendUniqueAuthOptions} options */
export const backendUniqueAuth = async (options) => {
  const { store } = options;

  const user = store.state.user;
  const hasContract = user && "selectedContract" in user && user.selectedContract !== null;
  const requestingToken = store.state.Auth.requestingAAToken;

  if (!user || !hasContract) {
    return Promise.reject(new Error("User not logged in or does not have contract selected"));
  }

  const service = backendUniqueAuthService();

  const { operatorCode, contractNumber } = user.selectedContract;
  const idmToken = store.state.Auth.idmAccessToken;
  const document = store.state.Auth.document;

  if (requestingToken) {
    let unsubscribe;

    const aaToken = await new Promise((resolve, reject) => {
      const rejectTimeout = setTimeout(() => {
        reject(new Error("Requesting AAToken timeout"));
      }, 10000);

      unsubscribe = store.subscribe((mutation) => {
        if (mutation.type === `Auth/${AuthMutations.SET_REQUESTING_AATOKEN}`) {
          if (mutation.payload === false) {
            clearTimeout(rejectTimeout);
            resolve(
              store.state.Auth.aaToken[`${operatorCode}${contractNumber}`]
            );
          }
        }
      });
    });

    unsubscribe();
    return aaToken;
  }
  
  try {
    store.commit(`Auth/${AuthMutations.SET_REQUESTING_AATOKEN}`, true);

    const authProviderType = store.state.Auth.ssoProvider;

    const aaToken = await service.getToken({
      operatorCode,
      contractNumber,
      idmToken,
      document,
      type: authProviderType
    });

    if (aaToken !== store.state.Auth.aaToken) {
      store.dispatch("Auth/setAAToken", {
        contractNumber: `${operatorCode}${contractNumber}`,
        token: aaToken
      });
    }

    return Promise.resolve(aaToken);
  } catch (error) {
    console.error(error);
  } finally {
    store.commit(`Auth/${AuthMutations.SET_REQUESTING_AATOKEN}`, false);
  }
};