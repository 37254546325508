<template>
  <fragment>
    <mcr-header
      homeUrl="/"
      :contrastFunc="setContrast"
      moreContractsPath=""
      isPublic
      :isCNPJ="false"
    ></mcr-header>
    <main class="mdn-Container">
      <slot></slot>
    </main>
    <mdn-footer-simple></mdn-footer-simple>
  </fragment>
</template>

<script>
import { mapState, mapActions } from "vuex";
import HeaderModule from "@minhaclarors/header";
import MondrianUIModule from "@/modules/ui/mondrian.js";
import mdnFooterSimple from "../components/MdnFooterSimple";

export default {
  name: "public-layout",
  computed: {
    ...mapState(["footerMenuList"])
  },
  components: {
    McrHeader: HeaderModule.component,
    mdnFooterSimple
  },
  methods: {
    ...mapActions(["getFooterMenu"]),
    setContrast: MondrianUIModule.setContrast
  },
  mounted() {
    this.getFooterMenu();
  }
};
</script>

<style src="@minhaclarors/header/dist/mcr-header.css" />
