const RECAPTCHA_KEY = process.env.VUE_APP_RECAPTCHA_KEY;

async function getToken(action) {
  return new Promise(resolve => {
    window.grecaptcha.ready(async function() {
      resolve(await window.grecaptcha.execute(RECAPTCHA_KEY, { action }));
    });
  });
}

export default {
  getToken
};
