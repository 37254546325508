const MODE = process.env.VUE_APP_MODE;

let dtActions = {};

const actionTypes = {
  CLICK: "click"
};

export default {
  //addSnippet: addSnippet,
  init: init,
  afterPageEnter: afterPageEnter,
  setAPIError: setAPIError,
  setSquad: setSquad,
  setCategory: setCategory,
  setContract: setContract,
  endSession: endSession,
  setUser: setUser,
  setOutage: setOutage,
  startAction: startAction,
  endAction: endAction,
  loadPage: loadPage,
  leavePage: leavePage,
  getPublicMethods: getPublicMethods
};

function afterPageEnter(pageMeta, selectedContract) {
  if (selectedContract) {
    setContract(selectedContract);
    setCategory(selectedContract);
  }

  setSquad(pageMeta.squad);
}

function loadPage(to) {
  if (to) {
    console.log("Load page: " + to.path);
  }
}
function leavePage(from) {
  if (from) {
    console.log("Leave page: " + from.path);
  }
}

function endSession() {
  if (window.dtrum && MODE !== "production") {
    window.dtrum.endSession();
  }
}

function endAction(actionName) {
  if (window.dtrum) {
    window.dtrum.leaveAction(dtActions[actionName]);
    delete dtActions[actionName];
  }
}

function init() {
  window.dtActionProperties = {};
}

function startAction(actionName, actionTypeName) {
  let actionType = actionTypes[actionTypeName] || null;
  let action = null;

  if (window.dtrum) {
    action = window.dtrum.enterAction(actionName, actionType);

    dtActions[actionName] = {
      action: action
    };
  }
}

function setSquad(squad) {
  window.dtActionProperties.squad = squad;
}

function setContract(contract) {
  window.dtActionProperties.contract =
    contract && contract.mcrCode ? contract.mcrCode : "no-contract-selected";
}

function setUser(tag) {
  if (window.dtrum && tag) {
    window.dtrum.identifyUser(tag);
  }
}

function setCategory(contract) {
  let category = "";

  if (contract) {
    switch (null) {
      case contract.customerClaroTv:
        category = "claro-tv";
        break;
      case contract.customerNetUno:
        category = "claro-netuno";
        break;
      default:
        category = "claro-residencial";
        break;
    }

    window.dtActionProperties.category = category;
  }
}

function setOutage(newOutage) {
  window.dtActionProperties.outage =
    newOutage && newOutage.listOfResult && newOutage.listOfResult.length > 0
      ? "sinal-nao-ok"
      : "sinal-ok";
}

function setAPIError(status, message) {
  window.dtActionProperties.api_error = `${status} - ${message}`;
}

function getPublicMethods() {
  return {
    setAPIError: setAPIError,
    startAction: startAction,
    endAction: endAction
  };
}
