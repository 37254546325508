import axios from "axios";
import axiosRetry from "axios-retry";

/**
 * @typedef {object} UserInfoResponse
 * @property {string} documentNumber
 * @property {Date} expiresWhen
 * @property {object} contract
 * @property {string} contract.operatorCode
 * @property {string} contract.contractNumber
 */

/**
 * @typedef {object} TokenAAResponse
 * @property {string} token
 * @property {number} expiresIn
 */

export function createAuthorizerService() {
  const $http = axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/digital/v1/authorizer`,
    headers: {
      "client-id": window.McrProps.APIGEE.TYPE
    }
  });

  axiosRetry($http, {
    retries: 3,
    retryDelay: retryCount => retryCount * 1000,
    retryCondition: error => error.response.status >= 500
  });

  /**
   * @param {string} tokenAA
   * @returns {Promise<UserInfoResponse>}
   */
  async function getUserInfoByTokenAA(tokenAA) {
    const { data } = await $http({
      url: "/userinfo/session",
      headers: {
        Authorization: `Bearer ${tokenAA}`,
        Accept: "application/json",
        "client-key": "fHtk9BT1x9V1UtVM282ZCrKPBXgjCh89",
        "x-client-key": "fHtk9BT1x9V1UtVM282ZCrKPBXgjCh89"
      }
    });

    const { expiresWhen, customerDocument, customerAccountId } = data.data;

    const contractNumber = customerAccountId;
    const documentNumber = customerDocument.number;

    const userInfo = {
      documentNumber,
      expiresWhen: new Date(expiresWhen)
    };

    if (customerAccountId) {
      userInfo.contract = {
        operatorCode: contractNumber.substring(0, 3),
        contractNumber: contractNumber.substring(3)
      };
    }

    return userInfo;
  }

  /**
   * @param {string} tokenPing
   * @returns {Promise<TokenAAResponse>}
   */
  async function getTokenAAByTokenPing(tokenPing) {
    const { data } = await $http({
      url: "/transfer/ping",
      headers: {
        "x-querystring": `tokenPing=${tokenPing}`,
        "client-key": window.McrProps.APIGEE.KEYS.MCR.PUBLIC,
        "x-client-key": window.McrProps.APIGEE.KEYS.MCR.PUBLIC
      }
    });

    const { token, expires_in } = data.data;

    return {
      token,
      expiresIn: expires_in
    };
  }

  /**
   * @param {string} tokenPing
   * @param {object} contract
   * @param {string} contract.operatorCode - Código da operadora
   * @param {string} contract.contractNumber - Número do contrato
   * @returns {Promise<TokenAAResponse>}
   */
  async function getTokenAAWithContractByTokenPing(tokenPing, contract) {
    const { data } = await $http({
      url: "/transfer/pingcontract",
      headers: {
        "client-key": window.McrProps.APIGEE.KEYS.MCR.PUBLIC,
        "x-client-key": window.McrProps.APIGEE.KEYS.MCR.PUBLIC,
        "x-querystring": `tokenPing=${tokenPing}&customerAccountId=${contract.operatorCode}${contract.contractNumber}`,
      }
    });

    const { token, expires_in } = data.data;

    return {
      token,
      expiresIn: expires_in
    };
  }

  /**
   * @param {string} tokenAA
   * @param {object} contract
   * @param {string} contract.operatorCode - Código da operadora
   * @param {string} contract.contractNumber - Número do contrato
   * @returns {Promise<TokenAAResponse>}
   */
  async function getTokenAAWithContractByTokenAA(tokenAA, contract) {
    const { data } = await $http({
      url: "/resources/choose",
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenAA}`,
        "client-key": "mcrdG2VNb8bebxDTPtAEvdfEhf6vwFRA",
        "x-client-key": "fHtk9BT1x9V1UtVM282ZCrKPBXgjCh89"
      },
      data: {
        contract: `${contract.operatorCode}${contract.contractNumber}`
      }
    });

    const { token, expires_in } = data.data;

    return {
      token,
      expiresIn: expires_in
    };
  }

  return {
    getUserInfoByTokenAA,
    getTokenAAByTokenPing,
    getTokenAAWithContractByTokenAA,
    getTokenAAWithContractByTokenPing
  };
}
