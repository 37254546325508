import Vue from "vue";
import { mapState } from "vuex";

import { Plugin } from "vue-fragment";
import VueInstall from "vue-pwa-install";
import RedirectModalPlugin from "@/plugins/RedirectModal";

import HeaderModule from "@minhaclarors/header";
import contractConector from "@minhaclarors/contract-connector";

import PrivateLayout from "@/layouts/PrivateLayout";
import PublicLayout from "@/layouts/PublicLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import MaintenanceLayout from "@/layouts/MaintenanceLayout";
import CrossLayout from "@/layouts/CrossLayout";
import TotemLayout from "@/layouts/TotemLayout";
import EmptyLayout from "@/layouts/EmptyLayout";

import MdnSpinner from "@/components/MdnSpinner";

import GTM from "@/modules/tracking/gtm";
import DT from "@/modules/tracking/dynatrace";
import { libraries } from "@/modules/libraries";

import "@minhaclarors/footer/dist/mcr-footer.css";
//import "mondrian-icons/dist/mondrian-icons-3.8.0.css";

import router from "./router";
import store from "./store";
import routerNavigationGuards from "./router/navigation-guards";

import App from "./App.vue";

Vue.component("private-layout", PrivateLayout);
Vue.component("public-layout", PublicLayout);
Vue.component("default-layout", DefaultLayout);
Vue.component("maintenance-layout", MaintenanceLayout);
Vue.component("cross-layout", CrossLayout);
Vue.component("totem-layout", TotemLayout);
Vue.component("empty-layout", EmptyLayout);
Vue.component("mdn-spinner", MdnSpinner);

Vue.config.productionTip = false;

Vue.use(Plugin);
Vue.use(HeaderModule.module, { store });
Vue.use(VueInstall);
Vue.use(RedirectModalPlugin);

/**
 * RouterGuard
 */
routerNavigationGuards({ router, store });

const MODE = process.env.VUE_APP_MODE;

contractConector.createConnection();
libraries.core.load();

new Vue({
  router,
  store,
  computed: {
    ...mapState(["pageState"])
  },
  created() {
    GTM.addSnippet();
    DT.init();
  },
  mounted() {
    if (MODE !== "production") {
      window.mutationLog = [];

      this.$store.subscribe((mutation, payload) => {
        window.mutationLog.push({
          mutation: mutation,
          payload: payload
        });
      });
    }
  },
  render: h => h(App)
}).$mount("#app");
