function consentModalSnippet(elemWrapper, callback) {
  if (window["consentimentoLGPD"]) {
    callback();
    return;
  }

  var domain = location.hostname;
  var script = null;
  var URL = null;
  var DEV = "https://dev-portal-lgpd.clarobrasil.mobi";
  var PROD = "https://claro.com.br";

  switch (domain) {
    case "mcrsit.clarobrasil.mobi":
      URL = DEV;
      break;
    case "minhaclaroresidencial3.claro.com.br":
      URL = PROD;
      break;
    case "minhaclaroresidencial.claro.com.br":
      URL = PROD;
      break;
    default:
      URL = DEV;
      break;
  }

  script = document.createElement("script");
  script.src = URL + "/privacidade/static/modal/v3/js/consentimento-lgpd.js";
  script.type = "text/javascript";

  script.addEventListener("load", () => {
    callback();
  });

  elemWrapper.append(script);
}

export default consentModalSnippet;
