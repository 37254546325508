import gwAppKeys from "./gw";

export default {
  IDMDOMAIN: "https://idmlabft.virtua.com.br",
  GWDOMAIN: "http://localhost:3000",
  CLAROTVDOMAIN: "https://clarotvdev.clarobrasil.mobi",
  PROVIDERDOMAIN: "http://localhost:3000",
  MNETAPIDOMAIN: "http://localhost:3000",
  CLIENTID: "MINHA_CLARO_RESIDENCIAL_STAGING",
  GEROID: "GERO-NET1250875",
  GEROINITDOMAIN: "=claro.com.br",
  GERODOMAIN: "https://person.cellit.io",
  GTMID: "GTM-PRRCNW3",
  SITEURL: "https://claro.com.br",
  GWAPPKEYS: gwAppKeys,
};
