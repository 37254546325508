import { TechnicalServices } from "@minhaclarors/services";
import { apigeeAdapter } from "@minhaclarors/mcr-gateway-parser";
import { createCustomerContractsService } from "../services/customerContractsService";
import * as mutationTypes from "./mutationTypes";

import { functionalityPermissionService } from "../services/functionalityPermissionService";

const GWDOMAIN = process.env.VUE_APP_GWDOMAIN;
const CLAROTVDOMAIN = process.env.VUE_APP_CLAROTVDOMAIN;
const WCPDOMAIN = process.env.VUE_APP_WCPDOMAIN;

export default {
  getFooterMenu: async ({ commit, rootState }, selectedContract) => {
    let menulist = [];
    const { idmAccessToken, document } = rootState.Auth;

    try {
      const response = await apigeeAdapter({
        url: `${window.McrProps.APIGEE.DOMAIN}/residential/v1/filtered-menu/menus/footer`,
        authType: "COMPLETE",
        key: window.McrProps.APIGEE.KEYS.ONBOARDING.LOGGED,
        operatorCode: selectedContract.operatorCode,
        contractNumber: selectedContract.contractNumber,
        document,
        token: idmAccessToken,
        headers: {
          citycode: selectedContract.cityCode
        }
      }).request();

      menulist = response.data;
    } catch (ex) {
      menulist = [];
    }

    commit(
      "SET_FOOTER_MENU_LIST",
      menulist.map(menuItem => {
        // NOTE: menuItem.items was replaced by menuItem.listOfSubMenu
        // TODO: Update to use only menuItem.listOfSubMenu on a future deploy
        const children = menuItem.items
          ? menuItem.items
          : menuItem.listOfSubMenu || [];

        const items = children.map(item => {
          const isRedirect =
            item.href.includes(CLAROTVDOMAIN) || item.href.includes(WCPDOMAIN);

          const href = isRedirect ? `/redirect?url=${item.href}` : item.href;

          return { ...item, href };
        });

        return { ...menuItem, items };
      })
    );
  },

  async setUser(store, contract) {
    if (window.dtrum) {
      window.dtrum.identifyUser(contract.documentNumber);
    }

    store.commit("SET_USER_AUTHENTICATED", contract);
  },

  setPageState({ commit }, stateName) {
    commit("SET_PAGE_STATE", stateName);
  },

  getOutageStatus: async ({ commit }, params) => {
    let outage = null;

    try {
      outage = await TechnicalServices({
        domain: GWDOMAIN,
        idmAccessToken: window.McrProps.IDM_ACCESS_TOKEN
      }).Technician.v1.getOutage(params.selectedContract);
    } catch (ex) {
      console.log(ex);
      outage = false;
    }

    commit("SET_OUTAGE", outage);
  },

  verifyIsPWA({ commit }) {
    let isPWA = window.matchMedia("(display-mode: standalone)").matches;

    commit("SET_IS_PWA", isPWA);
  },

  sendNotificationSelectionContract({ commit, state }, contract) {
    const { document, idmAccessToken: idmToken } = state.Auth;
    const { operatorCode, contractNumber } = contract;

    const customerContractsService = createCustomerContractsService({
      idmToken,
      operatorCode,
      contractNumber,
      document
    });

    customerContractsService.sendSelectContractNotification();

    commit("SET_CONTRACT_SELECTION_NOTIFICATION", true);
  },

  changeLoadingLibrary: ({ commit }, value) => {
    commit(mutationTypes.SET_LOADING_LIBRARY, value);
  },

  getFunctionalities: async ({ commit }) => {

    try {
      const response = await functionalityPermissionService().getFunctionalities();

      commit(mutationTypes.SET_FUNCTIONALITIES, response.data);
    } catch (e) {
      console.error(e);
    }
  }
};
