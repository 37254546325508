<template>
  <component :is="layout">
    <router-view />
    <mcr-loading-library-spinner />
    <mcr-loading-auth />
  </component>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dynatrace from "./modules/tracking/dynatrace";
import gtm from "./modules/tracking/gtm";
import { backendUniqueAuth } from "./modules/authentication/backendUniqueAuth";
import { createNetworkRequestsInterceptor } from "./utils/networkRequestsInterceptor";

import McrLoadingLibrarySpinner from "./components/McrLoadingLibrarySpinner.vue";
import McrLoadingAuth from "./components/McrLoadingAuth.vue";

const GWDOMAIN = process.env.VUE_APP_GWDOMAIN;
const MNETAPIDOMAIN = process.env.VUE_APP_MNETAPIDOMAIN;
const HOME_URL = process.env.VUE_APP_HOME_URL;
const WCP_HOME = process.env.VUE_APP_WCP_HOME;
const CTV_HOME = process.env.VUE_APP_CTV_HOME;
const CLAROTVDOMAIN = process.env.VUE_APP_CLAROTVDOMAIN;
const WCPDOMAIN = process.env.VUE_APP_WCPDOMAIN;
const GWCLIENTKEY = process.env.VUE_APP_GWCLIENTKEY;
const GWDOMAINAPIGEE = process.env.VUE_APP_GWDOMAINAPIGEE;
const GWCLIENTKEY_PRIVATE_NET = process.env.VUE_APP_GWCLIENTKEY_PRIVATE_NET;
const GWCLIENTKEY_PRIVATE_CTV = process.env.VUE_APP_GWCLIENTKEY_PRIVATE_CTV;
const GWCLIENTKEY_PRIVATE_CTV_APIGEE = process.env.VUE_APP_GWCLIENTKEY_PRIVATE_CTV_APIGEE;
const APIGEE_EASYINVOICE = process.env.VUE_APP_APIGEE_EASYINVOICE;

const defaultLayout = "default-layout";

export default {
  components: {
    McrLoadingLibrarySpinner,
    McrLoadingAuth
  },
  computed: {
    layout() {
      if (this.$route.meta && this.$route.meta.isTotem) {
        return "totem-layout";
      }

      if (
        this.$route.meta &&
        this.$route.meta.requiresAuth &&
        !this.$route.meta.oAuth2
      ) {
        return "private-layout";
      }

      if (this.$route.meta && this.$route.meta.isPublic) {
        if (this.$route.meta.isEmptyLayout) return "empty-layout";
        return this.$route.meta.isCross ? "cross-layout" : "public-layout";
      }

      if (this.$route.meta && this.$route.meta.isMaintenance) {
        return "maintenance-layout";
      }

      if (this.$route.meta && this.$route.meta.requiresPJAuth) {
        return "empty-layout";
      }

      return defaultLayout;
    },
    ...mapState("Auth", ["ssoProvider"]),
  },
  methods: {
    ...mapActions("PWA", ["verifyIsPWA"]),
    ...mapActions("Auth", ["logout"])
  },
  watch: {
    ssoProvider(value) {
      if (value === "PING") {
        window.McrProps.APIGEE_CLIENT_KEY = process.env.VUE_APP_APIGEE_PING_KEYS_MCR_LOGGED;
        window.McrProps.APIGEE.KEYS.ONBOARDING = {
          LOGGED: process.env.VUE_APP_APIGEE_PING_KEYS_MCR_LOGGED,
          PUBLIC: process.env.VUE_APP_APIGEE_PING_KEYS_MCR_PUBLIC
        };
        window.McrProps.APIGEE.KEYS.MCR = {
          LOGGED: process.env.VUE_APP_APIGEE_PING_KEYS_MCR_LOGGED,
          PUBLIC: process.env.VUE_APP_APIGEE_PING_KEYS_MCR_PUBLIC
        };
      }

      if (value === "IDM") {
        window.McrProps.APIGEE_CLIENT_KEY = process.env.VUE_APP_APIGEE_CLIENT_KEY;
        window.McrProps.APIGEE.KEYS.ONBOARDING = {
          LOGGED: process.env.VUE_APP_APIGEE_KEYS_ONBOARDING_LOGGED,
          PUBLIC: process.env.VUE_APP_APIGEE_KEYS_ONBOARDING_PUBLIC
        };
        window.McrProps.APIGEE.KEYS.MCR = {
          LOGGED: process.env.VUE_APP_APIGEE_KEYS_ONBOARDING_LOGGED,
          PUBLIC: process.env.VUE_APP_APIGEE_KEYS_ONBOARDING_PUBLIC
        };
      }
    }
  },
  mounted() {
    const gwAppKeys = {
      NO_AUTH: "e2af1ea0c7f9013762e1000d3ac06d76",
      IDM_AUTH: "c19a30f0b09b0137607d000d3ac06d76",
      IDM_AUTH_CONTRACT: "e9e24b40c78e013762d7000d3ac06d76",
      IDM_FRANCHISE_CONSUMPTION_APP: "bd144e80c7f9013762da000d3ac06d76",
      APIGEE_EASYINVOICE: APIGEE_EASYINVOICE
    };

    const clientKeysPrivate = {
      NET: GWCLIENTKEY_PRIVATE_NET,
      CTV: GWCLIENTKEY_PRIVATE_CTV,
      CTV_APIGEE: GWCLIENTKEY_PRIVATE_CTV_APIGEE
    }


    window.McrProps = {
      GWDOMAIN: GWDOMAIN,
      MNETAPIDOMAIN: MNETAPIDOMAIN,
      HOME_URL: HOME_URL,
      WCP_HOME: WCP_HOME,
      CTV_HOME: CTV_HOME,
      CLAROTVDOMAIN: CLAROTVDOMAIN,
      WCPDOMAIN: WCPDOMAIN,
      GWDOMAINAPIGEE: GWDOMAINAPIGEE,
      STORE: this.$root.$store,
      ROUTER: this.$root.$router,
      GWCLIENTKEY: GWCLIENTKEY,
      GWCLIENTKEYS_PRIVATE: clientKeysPrivate,
      gwAppKeys: gwAppKeys,
      dynatrace: dynatrace.getPublicMethods(),
      gtm: gtm.getPublicMethods(),
      APIGEE: {
        DOMAIN: GWDOMAINAPIGEE,
        TYPE: "SITERES",
        KEYS: {
          MCR: {
            PUBLIC: process.env.VUE_APP_APIGEE_KEYS_ONBOARDING_PUBLIC
          },
          BACKEND_UNIQUE: {
            KEY: process.env.VUE_APP_APIGEE_KEYS_BACKENDUNIQUE,
            XKEY: process.env.VUE_APP_APIGEE_KEYS_BACKENDUNIQUE_X
          }
        }
      },
      getAAToken: () => backendUniqueAuth({ store: this.$root.$store }),
    };

    /**
     * For legacy compatibility.
     */
    window.Environment = {
      zup: window.McrProps.GWDOMAIN,
      clarotv: window.McrProps.CLAROTVDOMAIN,
      gwAppKeys: gwAppKeys
    };

    this.verifyIsPWA();

    createNetworkRequestsInterceptor({
      id: "unauthorizedLogout",
      onLoad: ({ url, statusCode, responseText }) => {
        const whiteListByCodes = {
          403: ["/residential/v1/customer-contracts/contracts/details"]
        };
        const isApigee = url.startsWith(window.McrProps.APIGEE.DOMAIN);

        if (isApigee) {
          const isUnauthorized = [401].includes(statusCode);
          const isExpiredTokenMessage = responseText?.error?.detailedMessage === "User authorization check failed.";
          const isExpiredToken = [403].includes(statusCode) && isExpiredTokenMessage;
          const inWhiteList = whiteListByCodes[statusCode] ? whiteListByCodes[statusCode].some((path) => url.endsWith(path)) : false;

          if (inWhiteList && !isUnauthorized) {
            console.log("Request in white list, not logging out.");
            return;
          };

          if (isUnauthorized || isExpiredToken) {
            this.logout();
            return;
          }
        }
      }
    });
  }
};
</script>

<style>
html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
}

body.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

.migration-page {
  min-height: 400px;
}

main {
  min-height: 400px;
  position: relative;
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

section {
  height: 100%;
}

.from-app #main-header {
  display: none;
}

.from-app .mcr-footer {
  display: none;
}

.from-app .mcr-AppBar {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
