import Utils from "@minhaclarors/utils";
import authenticationByCookie from "../authentication/by-cookie";
import jsCookie from "js-cookie";
import originPathModule from '../authentication/originPath'
import { createAuthorizerService } from "../../services/authorizerService";

let store = null;
let to = null;
let next = null;
let idmAccessToken = null;
let isAuthenticated = false;
let code = null;

export default {
  async intercept(params) {
    store = params.store;
    to = params.to;
    next = params.next;

    isAuthenticated = store.getters["Auth/isAuthenticated"];

    if(!store.state.Auth.ssoProvider){
      await store.dispatch("Auth/getSSOProvider");
    }

    if (!isAuthenticated) {
      await Promise.all([
        await getTrustCircleFromUrl(),
        await getAuthByCookie()
      ]);
    }

    idmAccessToken =
      store.getters["Auth/getAccessToken"] ||
      window.localStorage.getItem("idmAccessToken");

    const ssoAccessToken = store.state["Auth/accessToken"] || window.localStorage.getItem("ssoAccessToken");

    if (ssoAccessToken) await store.dispatch("Auth/setAccessToken", ssoAccessToken);

    code =
      to.query.code ||
      (to.hash && to.hash.length > 0 ? getCodeFromHashParams(to.hash) : null);

    if (to.path == "/login") {
      if (code) {
        store.dispatch("Auth/getAccessToken", {
          code: code,
          next
        });
        return;
      }

      if (to.query["access_token"]) {
        await store.dispatch("Auth/checkSSOAccessToken", {
          accessToken: to.query["access_token"],
          next,
          originPath: to
        });
        return;
      }
    }

    if (idmAccessToken && !isAuthenticated) {
      originPathModule.set({
        path: to.path,
        query: to.query
      });

      store.dispatch("Auth/checkAccessToken", {
        idmAccessToken,
        next,
        originPath: to,
      });
      return;
    }

    if (!isAuthenticated) {

      originPathModule.set({
        path: to.path,
        query: to.query
      });

      // INFO: Caso tenha o parâmetro forceLogin=true, redireciona direto para o login. Se não, redireciona para a página pública.

      if ('forceLogin' in to.query && to.query.forceLogin === 'true') {
        store.dispatch("Auth/authenticate", to.path);
      } else {
        window.location.replace(process.env.VUE_APP_APPLESS);
      }

      return;
    }

    next();
  }
};

function getCodeFromHashParams(hash) {
  let hashParams = null;

  if (hash) {
    hashParams = getHashParams(hash);

    code = hashParams.code;
  }

  return code;
}

function getHashParams(hash) {
  let hashParams = hash ? hash.slice(1, hash.length).split("&") : [];
  let hashObjParams = {};

  hashParams.forEach(paramItem => {
    let param = paramItem ? paramItem.split("=") : null;

    if (param) {
      hashObjParams[param[0]] = param[1];
    }
  });

  return hashObjParams;
}

async function getTrustCircleFromUrl() {
  let params = null;
  let query = to.query["selected-contract"];
  let referrer = document.referrer;

  console.log("Referrer: ", referrer);
  if (
    // (referrer.includes("claro.com.br") ||
    //   referrer.includes("net.com.br") ||
    //   referrer === "") &&
    query
  ) {
    params = JSON.parse(Utils.b64DecodeUnicode(query));

    if (params.a || params.aa) {
      let token = params.aa || params.a;
      
      if (!params.aa) {
        const aaTokenEnriched = await createAuthorizerService().getTokenAAWithContractByTokenPing(
          token,
          {
            operatorCode: params.o,
            contractNumber: params.c
          }
        );

        window.localStorage.setItem("ssoAccessToken", token);
        jsCookie.set("SSO_ACCESS_TOKEN", token);

        token = aaTokenEnriched.token;
      } else {
        const userInfo = await createAuthorizerService().getUserInfoByTokenAA(token);

        if (userInfo.contract) {
          window.localStorage.setItem(
            "selectedContract",
            JSON.stringify({
              contractNumber: userInfo.contract.contractNumber,
              operatorCode: userInfo.contract.operatorCode
            })
          );
        }
      }

      window.localStorage.setItem("idmAccessToken", token);
    }

    if (params.c && params.o) {
      window.localStorage.setItem(
        "selectedContract",
        JSON.stringify({
          contractNumber: params.c,
          operatorCode: params.o
        })
      );
    }
  }
}

function getAppData(getCookie, b64Decode) {
  const cookieName = "AppData";
  const cookie = getCookie(cookieName);

  if (cookie) {
    const appData = b64Decode(cookie);

    if (appData) {
      return JSON.parse(appData);
    }
  }

  return null;
}

async function getAuthByCookie() {
  const params = authenticationByCookie(jsCookie.get, Utils.b64DecodeUnicode);

  if (params) {
    const isAuthByApp = params.isAuthByApp;

    if (isAuthByApp) {
      window.localStorage.setItem("from-app", isAuthByApp);
      window.isApp = true;
      window.AppData = getAppData(jsCookie.get, Utils.b64DecodeUnicode);
    }

    if (params.authData) {
      let token = params.authData.aaToken || params.authData.idmAccessToken;

      if (!params.authData.aaToken) {
        const aaToken = await createAuthorizerService().getTokenAAWithContractByTokenPing(
          params.authData.idmAccessToken,
          params.authData.selectedContract
        );

        window.localStorage.setItem("ssoAccessToken", params.authData.idmAccessToken);
        jsCookie.set("SSO_ACCESS_TOKEN", params.authData.idmAccessToken);

        token = aaToken.token;
      } else {
        const userInfo = await createAuthorizerService().getUserInfoByTokenAA(token);

        if (userInfo.contract) {
          window.localStorage.setItem(
            "selectedContract",
            JSON.stringify({
              contractNumber: userInfo.contract.contractNumber,
              operatorCode: userInfo.contract.operatorCode
            })
          );
        }
      }

      window.localStorage.setItem("idmAccessToken", token);
    }

    if (params.authData && params.authData.selectedContract.operatorCode && params.authData.selectedContract.contractNumber) {
      const { contractNumber, operatorCode } = params.authData.selectedContract;
      window.localStorage.setItem(
        "selectedContract",
        JSON.stringify({
          contractNumber: contractNumber,
          operatorCode: operatorCode
        })
      );
    }
  }
}
