export default {
  isAuthenticated: false,
  idmAccessToken: null,
  aaToken: {},
  accessToken: null,
  document: null,
  claroId: null,
  requestingAAToken: false,
  contractList: [],
  isLoading: false,
  ssoProvider: null
};
