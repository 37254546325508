const SCRIPT_ID = "clarity-script";

export const clarity = {
  addScript() {
    if (process.env.VUE_APP_MODE === "local") return;

    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.type = "text/javascript";
    script.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${process.env.VUE_APP_CLARITY_PJ_ID}");
    `;

    document.body.appendChild(script);
  },
  removeScript() {
    const script = document.getElementById(SCRIPT_ID);
    if (script) {
      script.remove();
    }
  }
};
