import contractConector from "@minhaclarors/contract-connector";
import Utils from "@minhaclarors/utils";

import {
  SET_FOOTER_MENU_LIST,
  SET_OUTAGE,
  SET_PAGE_STATE,
  SET_USER_AUTHENTICATED,
  SET_CONTRACT_SELECTION_NOTIFICATION,
  SET_LOADING_LIBRARY,
  SET_FUNCTIONALITIES
} from "./mutationTypes";

function formatedContractProductList(contract) {
  let productList = [];

  if (contract.serviceProducts) {
    contract.serviceProducts.forEach(item => {
      if (item.situation === "ATIVO" && item.businessLine !== "PAY TV") {
        if (item.tvVas) {
          productList.push("Claro box tv");
          return;
        }

        productList.push(Utils.capitalize(item.businessLine));
      }
    });
  }

  if (contract.segment.typeDescription == "PURPLE") productList.push("Celular");

  return productList;
}

function formatContract(contract, document) {
  const formatedContract = {};

  const addresses = contract.addresses[0];

  formatedContract.additionalData = contract.additionalData;
  formatedContract.operatorCode = contract.operatorCode;
  formatedContract.contractNumber = contract.contractNumber;
  formatedContract.addressId = addresses.addressId;
  formatedContract.addresses = contract.addresses;
  formatedContract.cityCode = addresses.city.cityCode || "02121";
  formatedContract.contractAddress = `${addresses.streetName}, ${addresses.number}, ${addresses.city.name} - ${addresses.stateOrProvince}`;
  formatedContract.customerClaroTV = contract.customerClaroTV;
  formatedContract.customerComboMulti = contract.customerComboMulti;
  formatedContract.customerNetUno = contract.customerNetUno;
  formatedContract.detailedError = false;
  formatedContract.docCnpj = document;
  formatedContract.docCpf = document;
  formatedContract.loaded = true;
  formatedContract.mcrCode = `${contract.operatorCode}${contract.contractNumber}`;
  formatedContract.operatorCod = contract.operatorCode;
  formatedContract.paymentData = contract.paymentData;
  formatedContract.productList = formatedContractProductList(contract).join(
    "/"
  );
  formatedContract.segment = contract.segment;
  formatedContract.serviceProducts = contract.serviceProducts;
  formatedContract.status = contract.status;
  formatedContract.subscriber = contract.subscriber;
  formatedContract.type = contract.type;
  formatedContract.userName = contract.subscriber.name;

  return formatedContract;
}

export default {
  [SET_FOOTER_MENU_LIST](state, newValue) {
    state.footerMenuList = newValue;
  },
  [SET_USER_AUTHENTICATED](state, newValue) {

    const logout = () => window.McrProps.STORE.dispatch("Auth/logout", new Event("logout"));
    const document = state.Auth.document;
    const selectedContract = newValue.selectedContract
      ? formatContract(
          state.Auth.contractList.find(
            c => c.contractNumber === newValue.selectedContract.contractNumber
          ),
          document
        )
      : null;
    const contractList = state.Auth.contractList || [];

    newValue.selectedContract = selectedContract;

    contractConector.setValue(
      selectedContract
        ? {
            operatorCode: selectedContract.operatorCode,
            contractNumber: selectedContract.contractNumber,
            document: state.Auth.document,
            idmToken: state.Auth.idmAccessToken,
            contracts: contractList.map(contract => ({
              operatorCode: contract.operatorCode,
              contractNumber: contract.contractNumber
            })),
            aaToken: newValue.aaToken
          }
        : undefined
    );

    state.user = {
      contractList,
      documentNumber: document,
      logout,
      selectedContract,
      unifiedPortal: true,
      userState: {
        CONTRACTS_LOADED: !!contractList.length,
        CONTRACT_SELECTED: !!selectedContract,
      }
    };
  },
  [SET_PAGE_STATE](state, name) {
    Object.keys(state.pageState).forEach(
      pageStateKey => (state.pageState[pageStateKey] = false)
    );

    state.pageState[name] = true;
  },

  [SET_OUTAGE](state, newValue) {
    state.outage = newValue;
  },

  [SET_CONTRACT_SELECTION_NOTIFICATION](state, newValue) {
    state.contractSelectionNotification = newValue;
  },

  [SET_LOADING_LIBRARY](state, newValue) {
    state.loadingLibrary = newValue;
  },

  [SET_FUNCTIONALITIES](state, newValue) {
    state.functionalities = newValue;
  }
};
