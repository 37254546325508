const libs = {
  React: "https://unpkg.com/react@18/umd/react.production.min.js",
  ReactDOM: "https://unpkg.com/react-dom@18/umd/react-dom.production.min.js"
};

const id = "react-libs";

export const reactLibraries = {
  async load() {
    console.log("[LIB][REACT] - Loading libraries...");

    const wrapperElement = document.createElement("div");
    wrapperElement.id = id;
    document.body.appendChild(wrapperElement);

    const wrapper = document.getElementById(id);

    for (const lib in libs) {
      const script = document.createElement("script");
      script.src = libs[lib];
      script.attributes.crossorigin = "anonymous";
      wrapper.appendChild(script);

      await new Promise(
        resolve =>
          (script.onload = () => {
            console.log(`[LIB][REACT] - Loaded ${lib}`);
            resolve();
          })
      );
    }

    console.log("[LIB][REACT] - Loaded all libraries");
    document.body.appendChild(wrapper);
  },
  unload() {
    const wrapper = document.getElementById(id);

    if (wrapper) {
      wrapper.remove();

      for (const lib in libs) {
        window[lib] = undefined;
      }

      console.log("[LIB][REACT] - Unloaded libraries");
    }
  }
};
