import axios from "axios";
import axiosRetry from "axios-retry";

/**
 *
 * @param {object} options
 * @param {string} options.tokenAA
 * @returns
 */
export function createBackendUniqueGraphqlService(options) {
  const $http = axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/mcm-backend-v1/v1/graphql`,
    headers: {
      "x-authorization": `Bearer ${options.tokenAA}`,
      "x-client-key": window.McrProps.APIGEE.KEYS.BACKEND_UNIQUE.XKEY
    }
  });

  axiosRetry($http, {
    retries: 3,
    retryDelay: retryCount => retryCount * 1000,
    retryCondition: error => error.response.status >= 500
  });

  /**
   * @returns {Promise<string>}
   */
  async function getClaroID() {
    const query = `#graphql
      query {
        MonitoramentoClaroID {
          ClaroID {
            id
          }
        }
      }
    `;

    const response = await $http.post("", {
      query
    });

    return response.data.data.MonitoramentoClaroID.ClaroID.id;
  }

  /**
   * @param {string} query
   */
  async function query(query) {
    const response = await $http({
      method: "POST",
      data: {
        query
      }
    });

    return response.data;
  }

  return {
    query,
    getClaroID
  };
}
