import Microfrontend from "../../pages/MicroFrontend.vue";
import Dashboard from "../../pages/home/DashboardV2.vue";

export default [
  {
    path: "/dashboard",
    name: "dashboard-old",
    meta: {
      requiresAuth: true,
      isMigratedPage: false,
      squad: "Home",
      title: "Home - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: () => import(
      /* webpackChunkName: "dashboard-old" */
      "../../pages/home/Dashboard.vue"
    )
  },
  {
    path: "/",
    name: "dashboard",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Home - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Dashboard
  },
  {
    path: "/dados/meus-dados",
    name: "customer-data",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Meus Dados - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: Microfrontend,
    props: {
      pageId: "customer-data-new"
    }
  }
];
