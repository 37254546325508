<template>
  <fragment>
    <mcr-header
      v-if="headerProps.idmAccessToken"
      moreContractsPath="/selecao-de-contrato"
      accessibilityLink="/atendimento-acessivel"
      homeUrl="/"
      :gwDomain="headerProps.gwDomain"
      :gwToken="headerProps.idmAccessToken"
      :logout="logout"
      :contrastFunc="setContrast"
      :selectedContract="headerProps.selectedContract"
      :filterMenu="filterMenu"
      :isCNPJ="isCNPJ"
      :menuClickEvent="checkIsAvailable"
      :modifyHref="modifyHref"
      @onChosenContract="headerProps.choosenContract"
      @onLoaded="headerProps.onHeaderLoaded"
      :extCustomerContracts="{ documentNumber: document, contractList }"
    ></mcr-header>
    <main class="mdn-Container">
      <template v-if="
        (user && user.selectedContract && !isTotem) ||
        $route.path === '/selecao-de-contrato'
      ">
        <slot></slot>
      </template>
      <div class="loading-box" v-else>
        <mdn-spinner absolute />
      </div>
    </main>
  </fragment>
</template>

<script>
import { mapState } from "vuex";
import HeaderModule from "@minhaclarors/header";

import UserIdentifier from "@/modules/user/identifier";
import MondrianUIModule from "@/modules/ui/mondrian.js";

import MdnSpinner from "../components/MdnSpinner.vue";

export default {
  mixins: [UserIdentifier],
  name: "totem-layout",
  components: {
    McrHeader: HeaderModule.component,
    MdnSpinner
  },
  computed: {
    ...mapState("Auth", ["contractList", "document"]),
  },
  methods: {
    setContrast: MondrianUIModule.setContrast
  },
  mounted() {
    window.localStorage.setItem("isTotem", true);
    window.document.body.classList.add("isTotem");
  },
  beforeDestroy() {
    window.document.body.classList.remove("isTotem");
  }
};
</script>

<style src="@minhaclarors/header/dist/mcr-header.css"></style>
<style lang="scss" scoped>
.mdn-Container-fluid {
  padding: 0;
}
</style>
<style lang="scss">
body.isTotem {
  header {
    .mdn-Menu-container,
    .mdn-Menu-top-list-right {
      display: none !important;
    }

    .mdn-Menu-top {
      display: flex !important;
    }
  }

  .loading-box {
    position: relative;
    width: 100%;
    height: 500px;
  }
}
</style>
