import axios from "axios";
import jsCookie from "js-cookie";

/**
 * @typedef Options
 * @type {object}
 * @property {string} [operatorCode] - Código da operadora
 * @property {string} [contractNumber] - Número do contrato
 * @property {string} [document] - Documento do cliente
 * @property {string} token - Token de autenticação
 * @property {"IDM" | "PING"} type - Provedor de autenticação 
 * @property {boolean} [ignoreCache] - Ignorar cache
 */

export function backendUniqueAuthService() {
  const cookieStorageKey = "AA_TOKEN";

  /**
   * 
   * @param {object} options
   * @param {string} options.operatorCode
   * @param {string} options.contractNumber
   * @param {string} options.idmToken
   * @returns 
   */
  async function getTokenByIDM(options) {
    const {
      operatorCode,
      contractNumber,
      idmToken
    } = options;

    const response = await axios({
      baseURL: window.McrProps.APIGEE.DOMAIN,
      url: "/mcm-backend-v1/api/token/residencial",
      method: "POST",
      headers: {
        "client-id": "SITERES",
        "client-key": process.env.VUE_APP_APIGEE_KEYS_BACKENDUNIQUE,
        "x-client-key": process.env.VUE_APP_APIGEE_KEYS_BACKENDUNIQUE_X
      },
      data: {
        contrato: `${operatorCode}${contractNumber}`,
        access_token: idmToken
      }
    });

    return response.data.data.token;
  }

  return {
    /**
     * @param {Options} options
     * @returns {Promise<string>}
     * @throws {Error}
     * @description Retorna o token do backend único
     */
    async getToken(options) {
      try {
        const { operatorCode, contractNumber, idmToken, type } = options;
        const mcrCode = `${operatorCode}${contractNumber}`;

        const contractCookieKey = `${cookieStorageKey}_${mcrCode}`;

        const cookieToken =  options.ignoreCache ? undefined : jsCookie.get(contractCookieKey);

        if (cookieToken) return Promise.resolve(cookieToken);

        let token;

        if (type === "IDM") {
          token = await getTokenByIDM({
            operatorCode,
            contractNumber,
            idmToken
          });
        } else if (type === "PING") {
          token = idmToken;
        } else {
          throw new Error("Invalid Auth Provider type");
        }

        if (!options.ignoreCache) {
          jsCookie.set(contractCookieKey, token, {
            expires: (1 / 24) * 0.75 // 45 minutes
          });
        }

        return token;
      } catch (error) {
        throw new Error(error);
      }
    },
    clearToken() {
      for (const cookieName in jsCookie.get()) {
        if (cookieName.startsWith(cookieStorageKey)) {
          jsCookie.remove(cookieName);
        }
      }
    }
  };
}
