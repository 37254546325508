//import SetTheme from "mondrian-vanilla/core/util/theme-changer";

export default {
  setContrast() {
    if (window.isContrast) {
      //SetTheme("claro");
    } else {
      //SetTheme("contrast");
    }

    window.isContrast = !window.isContrast;
  }
};
