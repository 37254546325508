<template>
  <div
    class="wrapper wrapper--isFull"
    v-if="isLoading"
  >
    <div class="wrapper__content">
      <div class="mdn-Spinner">
        <i class="mdn-Icon-claro mdn-Icon--lg"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Auth", ["isLoading"])
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  height: calc(100vh - var(--header-height));
  width: 100vw;
  top: var(--header-height);
  left: 0;
  background-color: var(--color-neutral-lightest);
  z-index: 99999;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--header-height);
  }

  &--isFull {
    height: 100vh;
    top: 0;
  }

  &__content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .mdn-Spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
  }
}
</style>
