import gwAppKeys from "./gw";

export default {
  IDMDOMAIN: "https://auth.netcombo.com.br",
  GWDOMAIN: "https://qa-gw.claro.com.br",
  CLAROTVDOMAIN: "https://mctv3.claro.com.br",
  PROVIDERDOMAIN: "https://qa-gw.claro.com.br",
  MNETAPIDOMAIN: "https://minhanet3.net.com.br",
  CLIENTID: "MinhaClaroSTAGING_PREPROD",
  GEROID: "GERO-NET1250875",
  GEROINITDOMAIN: ".claro.com.br",
  GERODOMAIN: "https://person.cellit.io",
  GTMID: "GTM-PRRCNW3",
  SITEURL: "https://claro.com.br",
  GWAPPKEYS: gwAppKeys,
};
