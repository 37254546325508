import gwAppKeys from "./gw";

export default {
  IDMDOMAIN: "https://auth.netcombo.com.br",
  GWDOMAIN: "https://prd-gw.claro.com.br",
  CLAROTVDOMAIN: "https://mctv.claro.com.br",
  PROVIDERDOMAIN: "https://prd-gw.claro.com.br",
  MNETAPIDOMAIN: "https://minhanet.net.com.br",
  CLIENTID: "MINHA_CLARO_RESIDENCIAL_HOMOLOG",
  GEROID: "GERO-NET1250875",
  GEROINITDOMAIN: ".claro.com.br",
  GERODOMAIN: "https://person.cellit.io",
  GTMID: "GTM-PRRCNW3",
  SITEURL: "https://claro.com.br",
  GWAPPKEYS: gwAppKeys,
};
