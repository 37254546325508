export const jsonUtil = {
  isString: (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  },
  parsedString: (str) => {
    return jsonUtil.isString(str) ? JSON.parse(str) : {};
  }
}
