function setContract(contract) {
  if (contract) {
    contract.operatorCod = contract.operatorCode;
  }

  window.activeContract = contract;
}

function setLoggedUser(loggeduser) {
  if (loggeduser && loggeduser.selectedContract) {
    loggeduser.selectedContract.operatorCod =
      loggeduser.selectedContract.operatorCode;
  }
  window.$loggedUser = loggeduser;
}

function createUserCookie(selectedContract) {
  var addressId = null;
  var mountedCookieString = null;
  var userName = window.activeContract
    ? window.activeContract.name
    : selectedContract.name;

  addressId = ",ai:" + selectedContract.addressId;

  mountedCookieString =
    "{n:'" +
    userName +
    "',u:'" +
    "_" +
    "',c:[],cs:" +
    selectedContract.operatorCode +
    formatContract(selectedContract.contractNumber) +
    addressId +
    "}";

  document.cookie = [
    "userCookie",
    "=",
    mountedCookieString.replace(new RegExp("'", "g"), '"'),
    "; domain=",
    window.location.host.toString().split(":")[0],
    "; path=/;"
  ].join("");
}

function formatContract(contractNumber) {
  return (contractNumber + "").padStart(9, "0");
}

export default {
  setContract: setContract,
  setLoggedUser: setLoggedUser,
  createUserCookie: createUserCookie,
  formatContract: formatContract
};
