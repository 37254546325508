<template>
  <div ref="wrapper"></div>
</template>

<script>
const ASSETS_DOMAIN = {
  SIT: "https://mcrsit.clarobrasil.mobi/mfe",
  PET: "http://minhaclaroresidencial3.claro.com.br/mfe",
  DEFAULT: `${process.env.VUE_APP_HOME_URL}/mfe`
};

export default {
  props: {
    pageId: {
      type: String,
      required: true
    },
    domain: {
      type: String
    },
    useSit: {
      type: Boolean,
      default: false
    },
    usePet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    assetsPath() {
      if (window.location.host.includes("localhost")) {
        if (this.domain) return this.domain;

        if (this.useSit) return ASSETS_DOMAIN["SIT"];
        if (this.usePet) return ASSETS_DOMAIN["PET"];

        return ASSETS_DOMAIN["PET"];
      }

      return ASSETS_DOMAIN["DEFAULT"];
    }
  },
  methods: {
    addScript() {
      const el = document.createElement("script");
      el.src = `${this.assetsPath}/js/${this.pageId}.js`;
      el.type = "text/javascript";
      el.setAttribute("data-page-id", this.pageId);
      document.body.appendChild(el);
    },
    addStyle() {
      const el = document.createElement("link");
      el.href = `${this.assetsPath}/css/${this.pageId}.css`;
      el.rel = "stylesheet";
      el.type = "text/css";
      el.setAttribute("data-page-id", this.pageId);
      document.head.appendChild(el);
    },
    clean(pageId) {
      this.$refs.wrapper.innerHTML = "";
      document
        .querySelectorAll(`[data-page-id="${pageId}"]`)
        .forEach(el => el.remove());
    },
    init() {
      const appContainer = document.createElement("div");
      appContainer.id = this.pageId;

      this.$refs.wrapper.appendChild(appContainer);

      this.addStyle();
      this.addScript();
    }
  },
  watch: {
    pageId(_, oldPageId) {
      this.clean(oldPageId);
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.clean(this.pageId);
  }
};
</script>
