<template>
  <fragment>
    <mcr-header
      homeUrl="/"
      :contrastFunc="setContrast"
      moreContractsPath=""
      isPublic
      :isCNPJ="false"
    ></mcr-header>
    <main class="mdn-Container">
      <slot></slot>
    </main>
    <mcr-footer :footerMenuList="footerMenuList"></mcr-footer>
  </fragment>
</template>

<script>
import { mapState, mapActions } from "vuex";
import HeaderModule from "@minhaclarors/header";
import MondrianUIModule from "@/modules/ui/mondrian.js";

import McrFooter from "@minhaclarors/footer";

export default {
  name: "maintenance-layout",
  computed: {
    ...mapState(["footerMenuList"])
  },
  components: {
    McrHeader: HeaderModule.component,
    McrFooter
  },
  methods: {
    ...mapActions(["getFooterMenu"]),
    setContrast: MondrianUIModule.setContrast
  },
  mounted() {
    this.getFooterMenu();
  }
};
</script>

<style src="@minhaclarors/header/dist/mcr-header.css" />
