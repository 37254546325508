const SET_IDM_ACCESS_TOKEN = "SET_IDM_ACCESS_TOKEN";
const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
const SET_LOGOFF = "SET_LOGOFF";
const SET_AUTHENTICATED_ERROR = "SET_AUTHENTICATED_ERROR";
const SET_DOCUMENT = "SET_DOCUMENT";
const SET_CLAROID = "SET_CLAROID";
const SET_AATOKEN = "SET_AATOKEN";
const SET_REQUESTING_AATOKEN = "SET_REQUESTING_AATOKEN";
const SET_CONTRACT_LIST = "SET_CONTRACT_LIST";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_SSO_PROVIDER = "SET_SSO_PROVIDER";
const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export {
  SET_IDM_ACCESS_TOKEN,
  SET_IS_AUTHENTICATED,
  SET_LOGOFF,
  SET_AUTHENTICATED_ERROR,
  SET_DOCUMENT,
  SET_CLAROID,
  SET_AATOKEN,
  SET_REQUESTING_AATOKEN,
  SET_CONTRACT_LIST,
  SET_IS_LOADING,
  SET_SSO_PROVIDER,
  SET_ACCESS_TOKEN,
};
