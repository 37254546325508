/**
 * @typedef {Object<string,string>} Query
 * @typedef {{path: string, query: Query}} OriginPath
 */

export const STORAGE_NAME = "originPath";
export const BLACK_LIST_PATHS = [
  "/selecao-de-contrato",
  "/login",
];

/**
 * @returns {OriginPath|undefined}
 */
function get() {
  let originPath = window.localStorage.getItem(STORAGE_NAME);
  if (originPath) originPath = JSON.parse(originPath);
  return originPath;
}

/**
 * @param {OriginPath} originPath
 * @example
 *
 * set({
 *   path: "/",
 *   query: {
 *     diagnostic: true,
 *   },
 * })
 */
function set(originPath) {
  if(!BLACK_LIST_PATHS.includes(originPath.path)) {
    window.localStorage.setItem(STORAGE_NAME, JSON.stringify(originPath));
  }
}

function remove() {
  window.localStorage.removeItem(STORAGE_NAME);
}

export default {
  get,
  set,
  remove,
};