<template>
  <div ref="consentimentWrapper">
    <div id="consentimento-lgpd"></div>
  </div>
</template>

<script>
import consentimentSnippet from "../../public/modal-consentimento-snippet";
import { mapState } from "vuex";

export default {
  name: "McrConsentimentModal",
  data() {
    return {
      exibitionPaths: ["/"]
    };
  },
  computed: {
    ...mapState("Auth", ["idmAccessToken"])
  },
  watch: {
    "$route.path"() {
      this.start();
    }
  },
  methods: {
    mountModal() {
      window["consentimentoLGPD"].mount({
        origin: "MCR-SITE",
        cpf: window.activeContract.docCpf
      });
    },
    start() {
      if (this.exibitionPaths.includes(this.$route.path)) {
        consentimentSnippet(this.$refs.consentimentWrapper, this.mountModal);
      }
    }
  },
  mounted() {
    this.start();
  }
};
</script>
