<template>
  <div class="protocol" v-if="protocol">
    <p class="mdn-Text mdn-Text--caption">Protocolo: {{ protocol }}</p>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import { createDashboardPjService } from "../services/dashboardPjService";

export default {
  computed: {
    ...mapState("PJ", ["contract", "document", "token", "protocol"])
  },
  methods: {
    ...mapMutations("PJ", ["SET_PROTOCOL"]),
    async fetchProtocol() {
      this.SET_PROTOCOL("");

      try {
        const response = await createDashboardPjService({
          operatorCode: this.contract.operatorCode,
          contractNumber: this.contract.contractNumber,
          token: this.token,
          document: this.document
        }).getProtocol();

        this.SET_PROTOCOL(response.number);
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    this.fetchProtocol();
  },
  watch: {
    contract() {
      console.log("[PROTOCOL] Contract changed");
      this.fetchProtocol();
    }
  }
};
</script>

<style lang="scss" scoped>
.protocol {
  padding-top: var(--theme-spacing-stack-sm);
  text-align: center;
}
</style>
