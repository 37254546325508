import RedirectModalComponent from "./RedirectModal.vue";

const RedirectModal = {
  install(Vue) {
    const eventBus = new Vue();

    Vue.component("redirect-modal", {
      extends: RedirectModalComponent,
      computed: {
        EventBus() {
          return eventBus;
        }
      }
    });

    Vue.prototype.$redirectModal = {
      show(params) {
        eventBus.$emit("show", params);
      }
    };
  }
};

export default RedirectModal;
