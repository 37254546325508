export default [
  {
    path: "/meu-tecnico/timeline",
    name: "timeline",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/historico",
    name: "timeline-history",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes",
    name: "timeline-details",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/chat",
    name: "timeline-chat",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/mapa",
    name: "timeline-map",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao",
    name: "timeline-evaluation",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/inicio",
    name: "timeline-evaluation-init",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/ajuda",
    name: "timeline-evaluation-help",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/claro",
    name: "timeline-evaluation-claro",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/validacao",
    name: "timeline-evaluation-validation",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/reagendar",
    name: "timeline-evaluation-reschedule",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/qualidades",
    name: "timeline-evaluation-quality",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/tecnico",
    name: "timeline-evaluation-technician",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/detalhes/avaliacao/obrigado",
    name: "timeline-evaluation-thanks",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/Timeline.vue"
      )
  },
  {
    path: "/meu-tecnico/timeline/shared",
    name: "timeline-shared",
    meta: {
      requiresAuth: false,
      isPublic: true,
      isMigratedPage: true,
      squad: "Meu Técnico",
      title: "Timeline - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "timeline" */
        "../../pages/my-technician/TimelineShared.vue"
      )
  }
];
