const libs = {
  axios: "https://unpkg.com/axios/dist/axios.min.js"
};

const id = "core-libs";

export const coreLibraries = {
  async load() {
    console.log("[LIB][CORE] - Loading libraries...");

    const wrapper = document.createElement("div");
    wrapper.id = id;

    for (const lib in libs) {
      const script = document.createElement("script");
      script.src = libs[lib];
      script.attributes.crossorigin = "anonymous";
      wrapper.appendChild(script);

      script.onload = () => {
        console.log(`[LIB][CORE] - Loaded ${lib}`);
      };
    }

    document.body.appendChild(wrapper);
  },
  unload() {
    document.getElementById(id).remove();

    for (const lib in libs) {
      window[lib] = undefined;
    }

    console.log("[LIB][CORE] - Unloaded libraries");
  }
};
