export default authData => {
  const parsedAuthData = {
    aaToken: authData.aa,
    idmAccessToken: authData.a,
    selectedContract: {
      contractNumber: authData.c,
      operatorCode: authData.o
    }
  };

  return parsedAuthData;
};

