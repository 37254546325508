<template>
  <mcr-diagnostic
    v-if="user && user.selectedContract"
    :isUnavailable="false"
    :selectedContract="user.selectedContract"
  >
  </mcr-diagnostic>
</template>

<script>
import McrDiagnostic from "@minhaclarors-pages-tecnica/diagnostic";
import { mapState } from "vuex";

export default {
  name: "mcr-diagnostic-wrapper",
  components: {
    McrDiagnostic: McrDiagnostic.component,
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style
  src="@minhaclarors-pages-tecnica/diagnostic/dist/mcr-diagnostic.css"
></style>
