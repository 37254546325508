import { mapState, mapActions } from "vuex";
import Identificator from "../core/identificator";
import GTM from "@/modules/tracking/gtm";
import DT from "@/modules/tracking/dynatrace";
import filter from "@/modules/security/filter";
import diagnosticHelper from "@/modules/partner-operation/diagnosticHelper";

const MODE = process.env.VUE_APP_MODE;
const WCPDOMAIN = process.env.VUE_APP_WCPDOMAIN;
const CLAROTVDOMAIN = process.env.VUE_APP_CLAROTVDOMAIN;

export default {
  computed: {
    ...mapState(["footerMenuList", "pageState", "outage", "user"]),
    ...mapState("Auth", ["idmAccessToken", "isAuthenticated"]),
    ...mapState("Header", ["selectedContract"])
  },
  data() {
    return {
      isCNPJ: false,
      headerProps: {}
    };
  },
  methods: {
    checkIsAvailable(page) {
      filter(
        {
          path: page.path,
          href: page.href,
          store: this.$store,
          selectedContract: this.selectedContract
        },
        () => {
          let path = page.path || page.href;

          if (this.$route.path !== path) {
            this.$router.push(path);
          }
        }
      );
    },
    modifyHref(href) {
      let newHref = href;

      if (href) {
        if (href.includes("/webcenter/") && !href.includes(WCPDOMAIN)) {
          newHref = WCPDOMAIN + href;
        }

        if (href.includes("#!/") && !href.includes(CLAROTVDOMAIN)) {
          newHref = CLAROTVDOMAIN + "/minha-claro/" + href;
        }
      }

      return newHref;
    },
    ...mapActions([
      "getFooterMenu",
      "setUser",
      "setPageState",
      "getOutageStatus",
      "sendNotificationSelectionContract",
    ]),
    ...mapActions("Auth", ["logout"]),
    ...mapActions("Header", ["getMenuList"]),
    filterMenu() {
      // TODO: Remove this
      return true;
    }
  },
  beforeMount() {
    window.McrProps.IDM_ACCESS_TOKEN = this.idmAccessToken;

    Identificator.init({
      env: MODE,
      tokenKey: "idmAccessToken",
      contractSelectionPath: "/selecao-de-contrato",
      originUrl: JSON.stringify({
        path: this.$route.path,
        query: this.$route.query
      }),
      router: this.$router,
      idmAccessToken: this.idmAccessToken,
      logoutFunc: e => this.logout(e),
      outageCheck: this.getOutageStatus,
      unifiedPortal: true,
      headerProps: this.headerProps,
      beforeChangeContract: beforeChangeContract.bind(this),
      afterChangeContract: afterChangeContract.bind(this),
      onAppReady: onAppReady.bind(this)
    });
  }
};

function onAppReady(params) {
  let outage = this.outage;
  let selectedContract = window.$loggedUser.selectedContract;
  let contractList = window.$loggedUser.contractList;
  console.info("AppReady", params);

  diagnosticHelper(this.$route);

  this.setUser(window.$loggedUser);
  this.isCNPJ =
    window.$loggedUser.documentNumber &&
    window.$loggedUser.documentNumber.length > 11
      ? true
      : false;

  window.setTimeout(() => {
    this.setPageState("READY");
  }, 1000);

  if (selectedContract) {
    filter({
      route: this.$route,
      store: this.$store,
      selectedContract,
      to: this.$route
    });

    this.getFooterMenu(selectedContract);

    GTM.trackSuccessLogin({
      outage,
      selectedContract,
      route: this.$route,
      qtdContracts: contractList ? contractList.length : 0
    });

    DT.setOutage(outage);
    DT.afterPageEnter(this.$route.meta, selectedContract);

    this.sendNotificationSelectionContract(selectedContract);

  }
}

function beforeChangeContract(params) {
  if (MODE !== "production") {
    console.log("BeforeChangeContract: " + params);
  }

  return false;
}

function afterChangeContract(params) {
  let selectedContract = params.selectedContract;

  (async () => {
    await this.setUser(window.$loggedUser);

    if (selectedContract) {
      filter({
        route: this.$route,
        store: this.$store,
        selectedContract,
        to: this.$route
      });

      await this.getOutageStatus({ selectedContract });
      this.sendNotificationSelectionContract(selectedContract);

      this.getFooterMenu(selectedContract);
      this.getMenuList();

      GTM.trackSuccessLogin({
        outage: this.outage,
        selectedContract,
        route: this.$route,
        qtdContracts: this.user.contractList ? this.user.contractList.length : 0
      });

      DT.setContract(selectedContract);
      DT.setCategory(selectedContract);
      DT.setOutage(this.outage);
    }

    if (this.$route && this.$route.meta && this.$route.meta.isMigratedPage) {
      this.setPageState("WAITING");
      window.setTimeout(() => {
        this.setPageState("READY");
      }, 1000);
    }
  })();
}
