const GTMID = process.env.VUE_APP_GTMID;
const MODE = process.env.VUE_APP_MODE;

export default {
  addSnippet: addSnippet,
  pushClaroIdInDataLayer: pushClaroIdInDataLayer,
  trackSuccessLogin: trackSuccessLogin,
  trackPageNavigation: trackPageNavigation,
  trackSuccessLogout: trackSuccessLogout,
  trackPublicPageNavigation: trackPublicPageNavigation,
  trackVirtualPageView: trackVirtualPageView,
  getPublicMethods: getPublicMethods,
  trackSendEvent: trackSendEvent,
  trackSendEventGA4: trackSendEventGA4
};

function addSnippet() {
  let mclaroGtmEl = document.querySelector("#mclaroGtmEl");

  if (!mclaroGtmEl) {
    if (MODE === "production") {
      console.info("GTM: Added Snippet id: " + GTMID);
    }

    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TV33R63");
  }
}

function trackSuccessLogin(params) {
  if (MODE !== "production") {
    console.info(
      "GTM: Success in Log-In",
      window.dataLayer[window.dataLayer.length - 1]
    );
  }

  trackPageNavigation(params);
}

function trackSuccessLogout() {
  console.info("Logged with success!");
}

function trackPageNavigation(params) {
  const { selectedContract, qtdContracts } = params;
  const brand = selectedContract.customerClaroTV ? "claro-tv" : "net";
  const cityCode = selectedContract.customerClaroTV
    ? "02121"
    : selectedContract.cityCode;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    dimension3: cityCode,
    dimension7: selectedContract.mcrCode,
    dimension8: selectedContract.contractNumber,
    dimension11: qtdContracts,
    dimension21: brand,
  });

  if (MODE === "local") {
    console.info(
      "GTM: Virtual page view",
      window.dataLayer[window.dataLayer.length - 1]
    );
  }
}

function trackVirtualPageView(path) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "virtual-pageview",
    page: path,
  });
}

function trackPublicPageNavigation(route) {
  let breadcrumb = route ? route.path : "";

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "virtual-pageview",
    dimension10: "nao-logado",
    dimension7: "minha-claro-residencial",
    dimension21: location.href,
    dimension22: breadcrumb,
  });

  if (MODE === "local") {
    console.info(
      "GTM: Virtual page view",
      window.dataLayer[window.dataLayer.length - 1]
    );
  }
}

function getPublicMethods() {
  return {
    trackVirtualPageView: trackVirtualPageView,
  };
}

function trackSendEvent(params) {
  let { eventCategory, eventAction, eventLabel } = params;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "event",
    eventCategory: `${eventCategory}`,
    eventAction: `${eventAction}`,
    eventLabel: `${eventLabel}`,
  });
}

function pushClaroIdInDataLayer(claroId) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "conversion",
    eventCategory: "claro-id",
    dimension1: claroId,
  });
}

/**
 * 
 * @param {{ event: 'clique' | 'callback'; section: string; title: string; callback?: string; }} event 
 * @returns 
 */
function trackSendEventGA4(event) {
  window.dataLayer = window.dataLayer || [];

  const eventData = {
    event: event.event,
    custom_section: event.section,
    custom_title: event.title
  };

  if (event.callback) {
    eventData.custom_callback = event.callback;
  }

  window.dataLayer.push(eventData);
}
