import axios from "axios";
import axiosRetry from "axios-retry";
import { createCacheStorage } from "../utils/cache";

/**
 * @typedef Options
 * @type {object}
 * @property {string} document - Número do documento
 * @property {string} token - Token do IDM
 * @property {number} [retries] - Número de tentativas
 * @property {AbortSignal} signal - AbortSignal
 */

/**
 * @typedef OptionsContract
 * @type {object}
 * @property {string} operatorCode - Código da operadora
 * @property {string} contractNumber - Número do contrato
 */

/**
 * @typedef ContractPageOptions
 * @type {object}
 * @property {number} pageNumber - Número da página
 * @property {number} pageSize - Tamanho da página
 */

/**
 * @typedef HttpClientOptions
 * @type {object}
 * @property {string} document - Número do documento
 * @property {string} token - Token
 * @property {string} [operatorCode] - Código da operadora
 * @property {string} [contractNumber] - Número do contrato
 * @property {number} [retries] - Número de tentativas
 * @property {AbortController} [signal] - AbortController
 */

const CACHE_TIME_IN_MINUTES = 60;

/** @param {HttpClientOptions} options */
const createApigeeHttp = options => {
  const {
    token,
    document,
    operatorCode,
    contractNumber,
    retries = 3,
    signal
  } = options;

  const querystring = new URLSearchParams({
    document: document
  });

  if (operatorCode && contractNumber) {
    querystring.append("operatorcode", operatorCode);
    querystring.append("contractnumber", contractNumber);
  }

  const client = axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/residential/v1/customer-contracts`,
    headers: {
      "client-id": "SITERES",
      authorization: `Bearer ${token}`,
      "x-client-key": process.env.VUE_APP_APIGEE_PING_KEY_MCR_LOGGED,
      "x-querystring": querystring.toString()
    },
    signal
  });

  axiosRetry(client, {
    retries,
    retryDelay: retryCount => retryCount * 1000,
    retryCondition: error => ![401, 403].includes(error.response.status)
  });

  return client;
};

/** @param {Options} serviceOptions */
export function createCustomerContractsPjService(serviceOptions) {
  const cacheStorage = createCacheStorage(serviceOptions.document);

  return {
    /**
     * @description Retorna se o usuário deve acessar o fluxo PJ ou não
     * @returns {Promise<boolean>}
     */
    async getIsPME() {
      const $http = createApigeeHttp({
        document: serviceOptions.document,
        token: serviceOptions.token,
        retries: serviceOptions.retries
      });

      try {
        const cacheKey = "is-pme";
        const cache = cacheStorage.get(cacheKey);

        if (cache) return cache;

        const { data } = await $http.get("/contracts/ispme");

        cacheStorage.set(cacheKey, Boolean(data), CACHE_TIME_IN_MINUTES);

        return Boolean(data);
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    /**
     * @param {ContractPageOptions} options
     * @description Retorna a lista de contratos
     */
    async getContractsPage(options) {
      const $http = createApigeeHttp({
        document: serviceOptions.document,
        token: serviceOptions.token,
        retries: serviceOptions.retries
      });

      try {
        const cacheKey = `contracts-[${options.pageNumber}][${options.pageSize}]`;
        const cache = cacheStorage.get(cacheKey);

        if (cache) {
          return {
            data: cache
          };
        }

        const response = await $http.get("/contracts/pagination", {
          params: {
            pageNumber: options.pageNumber,
            pageSize: options.pageSize
          }
        });

        cacheStorage.set(cacheKey, response.data, CACHE_TIME_IN_MINUTES);

        return response;
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * @param {OptionsContract} contract
     * @description Retorna o detalhe do contrato
     */
    async getContractDetail(contract) {
      const $http = createApigeeHttp({
        document: serviceOptions.document,
        token: serviceOptions.token,
        operatorCode: contract.operatorCode,
        contractNumber: contract.contractNumber,
        retries: serviceOptions.retries,
        signal: serviceOptions.signal
      });

      try {
        const cacheKey = `contract-[${contract.operatorCode}/${contract.contractNumber}]`;
        const cache = cacheStorage.get(cacheKey);

        if (cache) {
          return {
            data: cache
          };
        }

        const response = await $http.get("/contracts/details");
        cacheStorage.set(cacheKey, response.data, CACHE_TIME_IN_MINUTES);

        return response;
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * @param {OptionsContract} contract
     * @description Envia uma notificação para o portal admin que o contrato foi acessado
     */
    async sendContractSelection(contract) {
      try {
        const $http = createApigeeHttp({
          document: serviceOptions.document,
          token: serviceOptions.token,
          operatorCode: contract.operatorCode,
          contractNumber: contract.contractNumber
        });

        return $http.put("/contracts/notifications");
      } catch (error) {
        console.log(error);
      }
    }
  };
}
