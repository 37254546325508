import home from "./modules/home";
import financial from "./modules/financial";
import technical from "./modules/technical";
import myTechnical from "./modules/my-technical";
import attendance from "./modules/attendance";
import productsAndSales from "./modules/products-and-sales";
import pj from "./modules/pj";
import core from "./modules/core";

const routes = [
  ...home,
  ...financial,
  ...technical,
  ...myTechnical,
  ...attendance,
  ...productsAndSales,
  ...pj,
  ...core
];

export default routes;
