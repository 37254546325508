export default {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  outageStatus(state) {
    return state.outage;
  },

  hasSelectedContract(state) {
    return state.user && state.user.selectedContract;
  },

  customerType(state) {
    return state.user &&
      state.user.selectedContract &&
      state.user.selectedContract.customerClaroTV
      ? "claro-tv"
      : "net";
  }
};
