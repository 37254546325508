<script>
const REDIRECT_URL = "http://www.claro.com.br/mcr";

export default {
  data() {
    return {
      isOpen: true,
      redirectUrl: REDIRECT_URL
    };
  },
  computed: {
    isMobile() {
      const userAgent = window.navigator.userAgent;
      return userAgent.match(/Android/i) || userAgent.match(/iPhone/i);
    }
  },
  methods: {
    close() {
      this.isOpen = false;
    }
  }
};
</script>

<template>
  <div class="mcr-AppBar" v-if="isOpen && isMobile">
    <button
      class="mdn-Button mdn-Button--icon mdn-Button--secondaryInverse mcr-AppBar__close"
      @click="close"
      data-gtm-event-category="minha-claro-res:barra-app"
      data-gtm-event-action="clique:botao"
      data-gtm-event-label="fechar"
    >
      <i class="mdn-Icon-fechar mdn-Icon--sm"></i>
    </button>
    <div class="mcr-AppBar__appIcon">
      <img
        src="https://mondrian.claro.com.br/brands/vertical/default/minha-claro-residencial.svg"
      />
    </div>
    <p class="mdn-Text mdn-Text--body mdn-Text--inverse">
      Acesse com mais facilidade no nosso aplicativo
    </p>
    <a
      :href="redirectUrl"
      target="_blank"
      class="mdn-Button mdn-Button--primaryInverse mdn-Button--sm mcr-AppBar__download"
      data-gtm-event-category="minha-claro-res:barra-app"
      data-gtm-event-action="clique:botao"
      data-gtm-event-label="baixar-o-app"
      >Baixar o APP</a
    >
  </div>
</template>

<style lang="scss" scoped>
.mcr-AppBar {
  width: 100%;
  min-height: 92px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: var(--spacing-inline-xs);
  padding: var(--spacing-inline-xs);
  box-sizing: border-box;
  background-color: var(--color-brand-primary-dark);

  &__appIcon {
    min-width: 56px;
    width: 56px;
    height: 56px;
    background-color: var(--color-neutral-lightest);
    border: 1px solid var(--color-neutral-medium);
    border-radius: var(--border-radius-md);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: var(--border-radius-sm);

    img {
      width: 100%;
    }
  }

  .mdn-Text {
    font-size: 12px;
  }

  &__download {
    min-width: 100px;
  }

  &__close {
    min-width: 2rem;
  }
}
</style>
