import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";

import AuthModule from "./auth/index";
import PWAModule from "./pwa/index";
import PJModule from "./pj/index";

/**
 * Pages Stores modules
 */

Vue.use(Vuex);

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    Auth: AuthModule,
    PWA: PWAModule,
    PJ: PJModule
  }
});
