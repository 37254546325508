<template>
  <footer class="mdn-Footer mdn-Footer--simple" role="contentinfo">
    <div class="mdn-Container">
      <div class="mdn-Footer-content">
        <ul class="mdn-Footer-list">
          <li class="mdn-Footer-list-item">
            <a
              class="mdn-Footer-list-item-socialLink"
              href="https://www.facebook.com/clarobrasil"
            >
              <i
                aria-label="Facebook"
                class="mdn-Icon-facebook mdn-Icon--md"
              ></i>
            </a>
          </li>
          <li class="mdn-Footer-list-item">
            <a
              class="mdn-Footer-list-item-socialLink"
              href="https://twitter.com/clarobrasil"
            >
              <i aria-label="twitter" class="mdn-Icon-twitter mdn-Icon--md"></i>
            </a>
          </li>
          <li class="mdn-Footer-list-item">
            <a href="https://instagram.com/clarobrasil" class="mdn-Footer-list-item-socialLink">
              <i class="mdn-Icon-instagram mdn-Icon--md"></i>
            </a>
          </li>
          <li class="mdn-Footer-list-item">
            <a
              class="mdn-Footer-list-item-socialLink"
              href="https://www.youtube.com/user/ClaroBrasil"
            >
              <i aria-label="youtube" class="mdn-Icon-youtube mdn-Icon--md"></i>
            </a>
          </li>
          <li class="mdn-Footer-list-item">
            <a
              class="mdn-Footer-list-item-socialLink"
              href="https://pt.linkedin.com/company/clarobrasil"
            >
              <i
                aria-label="linkedin"
                class="mdn-Icon-linkedin mdn-Icon--md"
              ></i>
            </a>
          </li>
        </ul>
        <p class="mdn-Footer-disclaimer">
          © {{ currentYear }} Claro. Todos os direitos reservados.
        </p>
        <img
          src="https://mondrian.claro.com.br/brands/nosvg/assinatura-claro.png"
          width="114px"
          height="48px"
          alt="Logo minha claro residencial"
        />
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'mdn-footer-simples',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>