import PartnerOperations from "./partner-operations";
import enabledPages from "./enabled-pages.json";

const WCPDOMAIN = process.env.VUE_APP_WCPDOMAIN;
const CLAROTVDOMAIN = process.env.VUE_APP_CLAROTVDOMAIN;

const customerTypes = {
  CTV: "claro-tv",
  NET: "net"
};

function filter({ to, selectedContract, store, path, href }, cb) {
  const idmAccessToken = store.getters["Auth/getAccessToken"];
  const customerType = store.getters.customerType;
  const partnerOperationsParams = {
    operatorCode: selectedContract.operatorCode,
    contractNumber: selectedContract.contractNumber,
    idmAccessToken: idmAccessToken,
    customerType: customerType
  };

  const DOMAIN = customerType === customerTypes.CTV ? CLAROTVDOMAIN : WCPDOMAIN;

  let pageIsEnabled = false;

  if ((path && !to) || href === "/") {
    pageIsEnabled = checkPage(path || href, customerType, to);
  }

  if (to && to.path) {
    pageIsEnabled = checkPage(to.path, customerType, to);
  }

  if (pageIsEnabled) {
    if (cb) {
      cb();
    }
    return;
  }

  if (href) {
    if (!isPartnerPortal(href)) {
      location.href = href;
      return;
    }

    if (!href.includes(window.location.origin)) {
      href = href.includes(DOMAIN) ? href : DOMAIN + href;
    }

    PartnerOperations(partnerOperationsParams).redirect(href);

    return;
  }

  PartnerOperations(partnerOperationsParams).redirectToHome();
}

function checkPage(pageName, customerType, to) {
  if (
    pageName.includes("selecao-de-contrato") ||
    pageName.includes("login") ||
    pageName.includes("logout") ||
    pageName.includes("redirect") ||
    pageName.includes("manutencao")
  ) {
    return true;
  }

  // Suporte ao sistema atual

  const isEnabledInEnabledPages = enabledPages[customerType].includes(pageName);

  if (isEnabledInEnabledPages) return true;

  // --------------------------------------------------------------

  if (!to) {
    to = window.McrProps.ROUTER.resolve(pageName).route;
  }

  if (
    customerType === customerTypes.NET &&
    "isEnabledForNet" in (to.meta || {})
  ) {
    return to.meta.isEnabledForNet;
  }

  if (
    customerType === customerTypes.CTV &&
    "isEnabledForCtv" in (to.meta || {})
  ) {
    return to.meta.isEnabledForCtv;
  }

  return false;
}

function isPartnerPortal(href) {
  return href.includes("#!") || href.includes("webcenter");
}

export default filter;
