<template>
  <transition name="slide-fade">
    <a
      class="mcr-PromoCopa-EasterEgg"
      v-if="show"
      :href="promotion.redirect.url"
      target="_blank"
      @click="handleClick"
      :data-gtm-event-category="`minha-claro-res:${tagLabel}`"
      data-gtm-event-action="clique:botao"
      data-gtm-event-label="claro-copa:achei-clique-aqui"
    >
      <img
        :src="
          `${getAssetsUrl()}/images/home/campaigns/promo-copa/easteregg.png`
        "
        alt=""
        srcset=""
      />
    </a>
  </transition>
</template>

<script>
const ELEGIBLE_ROUTES = [
  "/",
  "/dados/meus-dados",
  "/financeiro/fatura-interativa",
  "/financeiro/forma-de-pagamento",
  "/suporte/visita-tecnica",
  "/atendimento/fale-com-a-claro"
];

export default {
  data() {
    return {
      enabled: false,
      show: false,
      isAutoDiagnostic: false,
      promotion: null
    };
  },
  computed: {
    redirectUrl() {
      return this.promotion.metadata.find(item => item.id === "local").value;
    },
    tagLabel() {
      const pathname = new URL(this.redirectUrl).pathname;
      const paths = pathname.split("/").filter(Boolean);

      if (paths.length >= 2) {
        return paths[paths.length - 1];
      }

      return "dashboard";
    }
  },
  methods: {
    checkIsEligible() {
      if (ELEGIBLE_ROUTES.includes(this.$route.path)) {
        const redirectPathName = new URL(this.redirectUrl).pathname;
        this.isAutoDiagnostic =
          this.$route.query && this.$route.query.diagnostic === "true";

        this.show = redirectPathName === this.$route.path;
      } else {
        this.show = false;
      }
    },
    getAssetsUrl() {
      return window.location.href.includes("localhost")
        ? "https://minhaclaroresidencial3.claro.com.br/assets"
        : "/assets";
    },
    handleUpdateEasterEgg(promotion) {
      this.promotion = promotion;
      this.enabled = true;
      this.checkIsEligible();
    },
    handleClick() {
      this.enabled = false;
      this.show = false;
    }
  },
  watch: {
    $route() {
      if (!this.enabled) return;
      this.checkIsEligible();
    }
  },
  mounted() {
    window.McrPromoCopaEasterEggUpdate = this.handleUpdateEasterEgg;
  }
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.4s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.4s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.mcr-PromoCopa-EasterEgg {
  position: fixed;
  right: var(--theme-spacing-inset-lg);
  bottom: var(--theme-spacing-inset-lg);
  z-index: 11;

  &:hover {
    cursor: pointer;
    img {
      transform: translateY(-5px);
    }
  }

  img {
    display: block;
    filter: drop-shadow(
      var(--shadow-offset-x-none) var(--shadow-offset-y-level-1)
        var(--shadow-blur-radius-level-1) rgba(0, 0, 0, 0.16)
    );
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 768px) {
    right: 0;
    bottom: 0;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
}
</style>
