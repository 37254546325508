<template>
  <div
    class="mdn-Spinner"
    :class="{
      ' mdn-Spinner--inverse': inverse,
      'mcr-isAbsolute': absolute,
      'mcr-isFixed': fixed
    }"
  >
    <i class="mdn-Icon-claro mdn-Icon--lg"></i>
  </div>
</template>

<script>
export default {
  name: "mdn-spinner",
  props: {
    inverse: {
      type: Boolean,
      default: () => false
    },
    absolute: {
      type: Boolean,
      default: () => false
    },
    fixed: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style lang="scss">
.mdn-Spinner.mcr-isAbsolute {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.8;
  top: 0;
  left: 0;
}

.mdn-Spinner.mcr-isFixed {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  background-color: var(--theme-background-color--default);
  z-index: 99;
}
</style>
