import PjLayout from "../../layouts/PjLayout.vue";

export default [
  {
    path: "/empresas",
    component: PjLayout,
    children: [
      {
        path: "",
        redirect: "financeiro/faturas",
        meta: {
          title: "Dashboard - Minha Claro Residencial",
          requiresPJAuth: true
        }
      },
      {
        path: "contratos",
        meta: {
          title: "Contratos - Minha Claro Residencial",
          requiresPJAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "empresas-contratos" */
            "../../pages/pj/Contracts"
          )
      },
      {
        path: "financeiro/faturas",
        meta: {
          title: "Faturas - Minha Claro Residencial",
          requiresPJAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "empresas-financeiro-faturas" */
            "../../pages/pj/Invoices"
          )
      },
      {
        path: "financeiro/faturas/:id",
        meta: {
          title: "Fatura - Minha Claro Residencial",
          requiresPJAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "empresas-financeiro-fatura" */
            "../../pages/pj/Invoice"
          )
      }
    ]
  }
];
