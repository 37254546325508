import axios from "axios";

/**
 * @typedef Options
 * @type {object}
 * @property {string} document - Número do documento
 * @property {string} idmToken - Token do IDM
 */

/** @param {Options} options */
const createApigeeHttp = (options) => {
  return axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/residential/v1/customer-contracts/contracts`,
    headers: {
      "client-id": "SITERES",
      "authorization": `Bearer ${options.idmToken}`,
      "x-client-key": window.McrProps.APIGEE.KEYS.MCR.LOGGED,
      "x-querystring": `document=${options.document}`,
    }
  });
}

/** @param {Options} options */
export function createCustomerContractsPublicService(options) {
  const $http = createApigeeHttp(options);

  return {
    /**
     * @description Retorna os contratos do cliente
     */
    async getContracts() {
      try {
        return $http.get("");
      } catch (error) {
        console.log(error);
      }
    },
  };
}
