export default [
  {
    path: "/suporte/agendamento",
    name: "scheduling",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento Técnico",
      title: "Agendamento - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-scheduling" */
        "../../pages/technical/Scheduling.vue"
      )
  },
  {
    path: "/suporte/solicitacao-mudanca-de-ponto",
    name: "request-point-change",

    meta: {
      requiresAuth: true,
      squad: "Atendimento Técnico",
      title: "Mudança Local de Ponto - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "request-point-change" */
        "../../pages/technical/RequestPointChange.vue"
      )
  },
  {
    path: "/suporte/diagnostic-web-view",
    name: "diagnostic-web-view",
    meta: {
      requiresAuth: true,
      squad: "Atendimento Técnico",
      title: "Diagnóstico - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "diagnostic-web-view" */
        "../../pages/technical/DiagnosticWebView.vue"
      )
  },

  {
    path: "/suporte/deep-link",
    name: "suport-deep-link",
    meta: {
      isPublic: true,
      requiresAuth: false,
      squad: "Atendimento Técnico",
      title: "Suporte- Minha Claro Residencial",
    },
    component: () =>
      import(
        "../../pages/technical/DeepLink.vue"
      )
  },

  {
    path: "/suporte/solicitacao-de-controle-remoto",
    name: "request-remote-control",
    meta: {
      requiresAuth: true,
      squad: "Atendimento Técnico",
      title: "Novo Controle Remoto - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "request-remote-control" */
        "../../pages/technical/RequestRemoteControl.vue"
      )
  },
  {
    path: "/suporte/swap-simples",
    name: "request-swap-simples",
    meta: {
      requiresAuth: true,
      squad: "Atendimento Técnico",
      title: "Troca de modem - Minha Claro Residencial",
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: () =>
      import(
        /* webpackChunkName: "swap-simples" */
        "../../pages/technical/RequestSwapSimples.vue"
      )
  },

  {
    path: "/suporte/visita-tecnica",
    name: "technical-visit",
    meta: {
      requiresAuth: true,
      squad: "Atendimento Técnico",
      title: "Visita Técnica - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "technical-visit" */
        "../../pages/technical/TechnicalVisit.vue"
      )
  },
  {
    path: "/suporte/senha-wifi-casa",
    name: "wifi-password",
    meta: {
      requiresAuth: true,
      squad: "Atendimento Técnico",
      title: "Senha Wifi - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "wifi-password" */
        "../../pages/technical/WifiPassword.vue"
      )
  },
  {
    path: "/suporte/tutorial/atualizacao-tv-4k",
    name: "diagnostic-public-area",
    meta: {
      isPublic: true,
      requiresAuth: false,
      squad: "Atendimento Técnico",
      title: "Tutorial - Atualização TV 4K"
    },
    component: () =>
      import(
        /* webpackChunkName: "diagnostic-new" */
        "../../pages/technical/DiagnosticPublicArea.vue"
      )
  },
  {
    path: "/suporte/redes-unificadas",
    name: "wifi-unified-net",
    meta: {
      isPublic: true,
      requiresAuth: false,
      squad: "Atendimento Técnico",
      title: "Redes unificadas"
    },
    component: () =>
      import(
        /* webpackChunkName: "wifi-password" */
        "../../pages/technical/WifiUnifiedNet.vue"
      )
  },
  {
    path: "/suporte/recomendacoes",
    name: "recommendations",
    meta: {
      isPublic: false,
      requiresAuth: true,
      isEnabledForNet: true,
      squad: "Atendimento Técnico",
      title: "Suas Recomendaçoes",
    },
    component: () =>
      import(
        "../../pages/technical/Recommendations.vue"
      )
  },
];
