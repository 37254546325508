import dayjs from "dayjs";

import originPathModule from "../../modules/authentication/originPath";
import recaptchaModule from "../../modules/security/recaptcha";
import { apigeeAdapter } from "@minhaclarors/mcr-gateway-parser";
import gtm from "@/modules/tracking/gtm.js";
import { functionalityPermissionService } from "../../services/functionalityPermissionService";
import {
  SET_AUTHENTICATED_ERROR,
  SET_IDM_ACCESS_TOKEN,
  SET_IS_AUTHENTICATED,
  SET_LOGOFF,
  SET_DOCUMENT,
  SET_CLAROID,
  SET_AATOKEN,
  SET_CONTRACT_LIST,
  SET_IS_LOADING,
  SET_SSO_PROVIDER,
  SET_ACCESS_TOKEN
} from "./mutationTypes";
import { createCustomerContractsPublicService } from "../../services/customerContractsPublicService";
import { createCustomerContractsService } from "../../services/customerContractsService";
import { createCustomerContractsPjService } from "../../services/customerContractsPjService";
import { createBackendUniqueGraphqlService } from "../../services/backendUniqueGraphqlService";
import { createAuthorizerService } from "../../services/authorizerService";
import jsCookie from "js-cookie";

const IDMDOMAIN = process.env.VUE_APP_IDMDOMAIN;
const PINGDOMAIN = process.env.VUE_APP_PINGDOMAIN;

const HOME_URL = process.env.VUE_APP_HOME_URL;
const CLIENTID = process.env.VUE_APP_CLIENTID;
const MODE = process.env.VUE_APP_MODE;

const SITE_URL = process.env.VUE_APP_SITEURL;

export default {
  generateTokenToContract: async (store, params) => {
    let tokenAA;

    const authorizerService = createAuthorizerService();

    const mcrCode = `${params.operatorCode}${params.contractNumber}`;
    const contractToken = store.state.aaToken[mcrCode];

    if (contractToken) {
      store.commit(SET_IDM_ACCESS_TOKEN, contractToken);
      return;
    }

    if (store.state.accessToken) {
      const response = await authorizerService.getTokenAAWithContractByTokenPing(
        store.state.accessToken,
        {
          operatorCode: params.operatorCode,
          contractNumber: params.contractNumber
        }
      );

      tokenAA = response.token;
    } else {
      const response = await authorizerService.getTokenAAWithContractByTokenAA(
        store.state.idmAccessToken,
        {
          operatorCode: params.operatorCode,
          contractNumber: params.contractNumber
        }
      );

      tokenAA = response.token;
    }
    
    store.commit(SET_IDM_ACCESS_TOKEN, tokenAA);
    store.commit(SET_AATOKEN, {
      contractNumber: mcrCode,
      token: tokenAA
    });
  },
  getContracts: async ({ state, commit, dispatch }, { idmToken }) => {
    try {
      const { document } = state;

      const customerContractsPublicService = createCustomerContractsPublicService({
        document,
        idmToken
      });

      const contracts = await customerContractsPublicService.getContracts();

      if (contracts.data.isNewStack) {
        window.location.href = "https://www.clarotvmais.com.br/faq#problem"
        return;
      }

      const authorizerService = createAuthorizerService();

      const contractsList = await Promise.all(
        contracts.data.contracts.map(async contract => {
          const customerContractsService = createCustomerContractsService({
            contractNumber: contract.contractNumber,
            operatorCode: contract.operatorCode,
            document,
            idmToken,
          });

          const mcrCode = `${contract.operatorCode}${contract.contractNumber}`;

          let contractToken = state.aaToken[mcrCode];

          if (!contractToken) {
            const contractTokenResponse = await authorizerService.getTokenAAWithContractByTokenAA(
              jsCookie.get("AA_TOKEN") || idmToken,
              {
                operatorCode: contract.operatorCode,
                contractNumber: contract.contractNumber
              }
            );
  
            commit(SET_AATOKEN, {
              contractNumber: mcrCode,
              token: contractTokenResponse.token
            });

            contractToken = contractTokenResponse.token;
          }
  
          const contractDetail = await customerContractsService.getContractDetails(contractToken);

          return contractDetail.data;
        })
      );

      commit(SET_CONTRACT_LIST, contractsList);
      commit(SET_IS_LOADING, false);
    } catch(error) {
      commit(SET_AUTHENTICATED_ERROR, error);
      dispatch("logout", new Event("logout"));
    }
  },
  getAccessToken: async ({ state, commit, dispatch }, params) => {
    let code = params.code;
    let next = params.next;

    try {
      commit(SET_IS_LOADING, true);
      const recaptchaToken = await recaptchaModule.getToken("login");

      const response = await apigeeAdapter({
        url: `${window.McrProps.APIGEE.DOMAIN}/residential/v1/auth-provider/access-token`,
        authType: "NONE",
        key: window.McrProps.APIGEE.KEYS.ONBOARDING.PUBLIC,
        recaptcha: recaptchaToken,
        headers: state.ssoProvider === "PING" ? {
          "ping-flow": true 
        } : {}
      }).request({
        method: "POST",
        body: JSON.stringify({
          code,
          clientId: CLIENTID
        })
      });

      if (!response.data.token) {
        throw new Error("response don't have token");
      }

      dispatch("setAccessToken", response.data.token);

      commit(SET_DOCUMENT, response.data.document);
      commit(SET_CLAROID, response.data.claroId);

      const enterPjFlux = await createCustomerContractsPjService({
        document: response.data.document,
        token: response.data.token
      }).getIsPME();

      if (enterPjFlux) {
        commit("PJ/SET_DOCUMENT", response.data.document, { root: true });
        commit("PJ/SET_TOKEN", response.data.token, { root: true });
        commit("PJ/SET_IS_PME", true, { root: true });

        next("/empresas");
        commit(SET_IS_LOADING, false);
        return;
      }

      const tokenAAResponse = await createAuthorizerService().getTokenAAByTokenPing(response.data.token);
      jsCookie.set("AA_TOKEN", tokenAAResponse.token);

      commit(SET_IDM_ACCESS_TOKEN, tokenAAResponse.token);

      await dispatch("getContracts", { idmToken: tokenAAResponse.token });

      commit(SET_IS_AUTHENTICATED, true);

      gtm.pushClaroIdInDataLayer(response.data.claroId);
      next();
    } catch (ex) {
      commit(SET_AUTHENTICATED_ERROR, ex);
    }
  },
  checkAccessToken: async ({ commit, dispatch }, params) => {
    let idmAccessToken = params.idmAccessToken;
    let next = params.next;
    let originPath = params.originPath;

    try {
      commit(SET_IS_LOADING, true);

      const userInfo = await createAuthorizerService().getUserInfoByTokenAA(idmAccessToken);

      if (Date.now() >= dayjs(userInfo.expiresWhen).add(3, "hour").valueOf()) {
        throw new Error("Token expired");
      }

      if (userInfo.contract) {
        commit(SET_AATOKEN, {
          contractNumber: `${userInfo.contract.operatorCode}${userInfo.contract.contractNumber}`,
          token: idmAccessToken
        });
      }

      try {
        const claroID = await createBackendUniqueGraphqlService({ tokenAA: idmAccessToken }).getClaroID();
        commit(SET_CLAROID, claroID);
      } catch (e) {
        console.log("Error on getClaroID");
        commit(SET_CLAROID, "");
      }

      commit(
        SET_IDM_ACCESS_TOKEN,
        idmAccessToken
      );

      commit(SET_DOCUMENT, userInfo.documentNumber);

      await dispatch("getContracts", { idmToken: idmAccessToken });

      commit(SET_IS_AUTHENTICATED, true);

      next();
    } catch (ex) {
      console.log(ex);
      commit(SET_AUTHENTICATED_ERROR, ex);
      dispatch("authenticate", originPath);
    }
  },

  checkSSOAccessToken: async ({ commit, dispatch }, params) => {
    let accessToken = params.accessToken;
    let next = params.next;
    let originPath = params.originPath;

    try {
      commit(SET_IS_LOADING, true);

      dispatch("setAccessToken", accessToken);

      const tokenAAResponse = await createAuthorizerService().getTokenAAByTokenPing(accessToken);
      const userInfo = await createAuthorizerService().getUserInfoByTokenAA(tokenAAResponse.token);

      commit(SET_IDM_ACCESS_TOKEN, tokenAAResponse.token);
      commit(SET_DOCUMENT, userInfo.documentNumber);

      try {
        const claroID = await createBackendUniqueGraphqlService({ tokenAA: tokenAAResponse.token }).getClaroID();
        commit(SET_CLAROID, claroID);
      } catch (e) {
        console.log("Error on getClaroID");
        commit(SET_CLAROID, "");
      }

      await dispatch("getContracts", { idmToken: tokenAAResponse.token });

      commit(SET_IS_AUTHENTICATED, true);

      next();
    } catch (ex) {
      console.log(ex);
      commit(SET_AUTHENTICATED_ERROR, ex);
      dispatch("authenticate", originPath);
    }
  },

  setAAToken({ commit }, data) {
    commit(SET_AATOKEN, data);
  },

  async authenticate({ state, dispatch }, originPath) {
    window.localStorage.removeItem("selectedContract");
    window.sessionStorage.setItem("originPath", originPath);

    if (!state.ssoProvider) {
      await dispatch("getSSOProvider");
    }

    const ssoProvider = state.ssoProvider;

    const redirectUrl = new URL("/login", HOME_URL);
    
    let loginUrl;

    if (ssoProvider === "PING") {
      loginUrl = new URL("/authorize", PINGDOMAIN);

      loginUrl.searchParams.append("client_id", CLIENTID);
      loginUrl.searchParams.append("redirect_uri", redirectUrl.href);
      loginUrl.searchParams.append("response_type", "code");
      loginUrl.searchParams.append("scope", "openid minha_net net_profile net_devices");
      loginUrl.searchParams.append("authMs", "UP,EP,DOCP,OTP");
    } else {
      loginUrl = new URL("/web/login.html", IDMDOMAIN);

      loginUrl.searchParams.append("client_id", CLIENTID);
      loginUrl.searchParams.append("redirect_uri", redirectUrl.href);
      loginUrl.searchParams.append("response_type", "code");
      loginUrl.searchParams.append("scope", "openid minha_net");
      loginUrl.searchParams.append("authMs", "UP,EP,DOCP,OTP");
    }

    if (process.env.NODE_ENV === "development") {
      redirectUrl.searchParams.append("code", "123456");

      window.location.href = redirectUrl.href; // eslint-disable-line
      return;
    }

    window.location.href = loginUrl.href; // eslint-disable-line
  },

  async logout({ commit, state }, e) {
    const redirectUri = window.location.origin + "/logout";

    if (e) {
      e.preventDefault();
    } else if (MODE === "local") {
      console.log("Logout called, but only works in real environment");
      return;
    }

    const ssoProvider = state.ssoProvider;
    const accessToken = state.accessToken;

    commit(SET_LOGOFF);
    originPathModule.remove();

    if (!accessToken) {
      window.location.href = redirectUri;
      return;
    }

    if (ssoProvider === "PING") {
      window.location.href = `${SITE_URL}/credencial/endsession?clientid=${CLIENTID}&redirect=${redirectUri}&token=${accessToken}`;
    }

    if (ssoProvider === "IDM") {
      window.location.href = `${IDMDOMAIN}/endsession?client_id=${CLIENTID}&redirect_uri=${redirectUri}`;
    }
  },

  async getSSOProvider({ commit }) {
    const ssoProviders = {
      PING: "PING",
      IDM: "IDM"
    };

    let ssoProvider = ssoProviders.IDM;

    try {
      const isPing = await functionalityPermissionService().getFunctionality(
        "SSO-PING-WEB"
      );

      if (isPing) {
        ssoProvider = ssoProviders.PING;
      }
    } catch (e) {
      console.log("Error on getSSOProvider");
    } finally {
      commit(SET_SSO_PROVIDER, ssoProvider);
    }
  },

  setAccessToken({ commit }, token) {
    window.localStorage.setItem("ssoAccessToken", token);
    commit(SET_ACCESS_TOKEN, token);
  }
};
