import axios from "axios";
import axiosRetry from "axios-retry";

import { createCacheStorage } from "../utils/cache";

/**
 * @typedef Options
 * @type {object}
 * @property {string} document - Número do documento
 * @property {string} token - Token do IDM
 * @property {string} operatorCode - Código da operadora
 * @property {string} contractNumber - Número do contrato
 * @property {number} [retries] - Número de tentativas
 */

/** @param {Options} options */
const createApigeeHttp = options => {
  const {
    token,
    document,
    operatorCode,
    contractNumber,
    retries = 2
  } = options;

  const querystring = new URLSearchParams({
    document,
    operatorCode,
    contractNumber
  });

  const client = axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/residential`,
    headers: {
      "client-id": "SITERES",
      authorization: `Bearer ${token}`,
      "x-client-key": process.env.VUE_APP_APIGEE_PING_KEY_MCR_LOGGED,
      "x-querystring": querystring.toString()
    }
  });

  axiosRetry(client, {
    retries,
    retryDelay: retryCount => retryCount * 1000,
    retryCondition: error => ![401, 403].includes(error.response.status)
  });

  return client;
};

/** @param {Options} serviceOptions */
export function createDashboardPjService(serviceOptions) {
  const $http = createApigeeHttp({
    document: serviceOptions.document,
    token: serviceOptions.token,
    contractNumber: serviceOptions.contractNumber,
    operatorCode: serviceOptions.operatorCode,
    retries: serviceOptions.retries
  });
  const cacheStorage = createCacheStorage(serviceOptions.document);

  return {
    async getProtocol() {
      try {
        const cacheKey = `protocol-[${serviceOptions.operatorCode}/${serviceOptions.contractNumber}]`;
        const cache = cacheStorage.get(cacheKey);

        if (cache) return cache;

        const response = await $http.post("/v1/dashboard/generateProtocol", {
          reasonId: 25,
          send: true
        });

        cacheStorage.set(cacheKey, response.data);

        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error("Erro ao gerar protocolo");
      }
    }
  };
}
