import Cookies from "js-cookie";

import Auth from "@/modules/security/auth";
import dynatrace from "@/modules/tracking/dynatrace.js";
import gtm from "@/modules/tracking/gtm.js";
import filter from "../modules/security/filter";
import { libraries } from "../modules/libraries";

import { createCustomerContractsPjService } from "../services/customerContractsPjService";
import { createAuthProviderService } from "../services/authProviderService";

const NavigationGuards = ({ router, store }) => {
  router.beforeEach(async (to, from, next) => {
    if (to.fullPath === "/public") {
      window.location.replace(process.env.VUE_APP_APPLESS);
    }

    if ("meta" in to && to.meta.isTotem) {
      to.query.platform = "totem";
    }

    window.scrollTo({ top: 0, behavior: "smooth" });

    store.dispatch("changeLoadingLibrary", true);
    
    await libraries.routerEnterAdapter(to, from);

    checkSelectedContractNavigation(to, from);

    const nextGuard = params => {
      const hasSelectedContract = store.getters.hasSelectedContract;

      if (hasSelectedContract) {
        filter({ to, selectedContract: hasSelectedContract, store }, next);
        store.dispatch("changeLoadingLibrary", false);
        return;
      }

      store.dispatch("changeLoadingLibrary", false);
      next(params);
    };

    // eslint-disable-next-line require-atomic-updates
    window.document.title = to.meta ? to.meta.title : "Minha Claro Residencial";

    dynatrace.leavePage(from);

    if (to.meta && to.meta.requiresAuth) {
      await Auth.intercept({ to, from, next: nextGuard, router, store });
      store.dispatch("changeLoadingLibrary", false);
      return;
    }

    // PJ Auth Flux
    if (to.meta && to.meta.requiresPJAuth) {
      const token = store.state.PJ.token;
      const contract = store.state.PJ.contract;

      if (!token) {
        const tokenCookie = Cookies.get("SSO_ACCESS_TOKEN");

        if (!tokenCookie) {
          store.dispatch("Auth/authenticate");
          return;
        } else {
          try {
            const response = await createAuthProviderService().checkIDMToken({
              idmToken: tokenCookie
            });
            const isPME =
              store.state.PJ.isPME ||
              (await createCustomerContractsPjService({
                document: response.data.document,
                token: response.data.token
              }).getIsPME());

            if (!isPME) {
              store.dispatch("Auth/logout", new Event());
              return;
            }

            store.commit("PJ/SET_TOKEN", response.data.token);
            store.commit("PJ/SET_DOCUMENT", response.data.document);
            store.commit("PJ/SET_IS_PME", true);
          } catch (error) {
            console.error("[PJ] - Error on checkIDMToken", error);
            store.dispatch("Auth/logout");
          }
        }
      }

      if (!store.state.PJ.document) {
        const response = await createAuthProviderService().checkIDMToken({
          idmToken: store.state.PJ.token
        });

        store.commit("PJ/SET_DOCUMENT", response.data.document);
      }

      if (!contract) {
        const contractCookie = Cookies.get("PJ_CONTRACT_NUMBER");
        const toContractsPage = to.path === "/empresas/contratos";

        if (!contractCookie) {
          if (!toContractsPage) {
            next("/empresas/contratos");
            console.log("[PJ] - No contract selected");
            store.dispatch("changeLoadingLibrary", false);
            return;
          }
        } else {
          const contractData = await createCustomerContractsPjService({
            document: store.state.PJ.document,
            token: store.state.PJ.token
          }).getContractDetail({
            operatorCode: contractCookie.substring(0, 3),
            contractNumber: contractCookie.substring(3)
          });

          store.commit("PJ/SET_CONTRACT", contractData.data, { root: true });
        }
      }
    }

    nextGuard();
  });

  router.afterEach(async (to, from) => {
    let isAuthenticated = null;
    let selectedContract = null;
    let contractList = null;

    await libraries.routerLeaveAdapter(to, from);

    dynatrace.loadPage(to);

    if (to.meta && to.meta.isPublic) {
      gtm.trackPublicPageNavigation(to);
    }

    if (to.meta && to.meta.requiresAuth) {
      isAuthenticated = store.getters["Auth/isAuthenticated"];
      selectedContract = store.state.Header.selectedContract;
      contractList = store.state.Header.contractList;

      if (selectedContract) {
        gtm.trackPageNavigation({
          selectedContract,
          isAuthenticated,
          qtdContracts: contractList ? contractList.length : 0,
          route: to
        });
      }
    }

    gtm.trackVirtualPageView(to.fullPath);
    dynatrace.afterPageEnter(
      to.meta,
      store.state.Header.selectedContract,
      store.state.outage
    );
  });
};

/**
 * Will prevent redirect to selected contract without originUrl
 * @param {*} to
 * @param {*} from
 */
function checkSelectedContractNavigation(to, from) {
  const SELECT_CONTRACT_ROUTE_NAME = "select-contract";
  const ORIGIN_URL = window.sessionStorage.getItem("originUrl");

  if (to.name === SELECT_CONTRACT_ROUTE_NAME && !ORIGIN_URL) {
    window.sessionStorage.setItem(
      "originUrl",
      JSON.stringify({ path: from.path, query: from.query })
    );
  }
}

export default NavigationGuards;
