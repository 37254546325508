import axios from "axios";
import recaptcha from "../modules/security/recaptcha";

const IDM_CLIENT_ID = process.env.VUE_APP_CLIENTID;

/** @param {Options} options */
const createApigeeHttp = () => {
  return axios.create({
    baseURL: `${window.McrProps.APIGEE.DOMAIN}/residential/v1/auth-provider/access-token`,
    headers: {
      "client-id": "SITERES",
      "x-client-key": window.McrProps.APIGEE.KEYS.MCR.PUBLIC
    }
  });
};

/** @param {Options} options */
export function createAuthProviderService() {
  const $http = createApigeeHttp();

  return {
    /**
     * @param {object} params
     * @param {string} params.code - Código de autorização
     * @description Retorna o token de acesso do IDM com base no código de autorização
     */
    async getIDMToken(params) {
      try {
        const recaptchaToken = await recaptcha.getToken("login");

        return $http({
          url: "",
          method: "post",
          headers: {
            "x-captcha-token": recaptchaToken
          },
          data: {
            clientId: IDM_CLIENT_ID,
            code: params.code
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * @param {object} params
     * @param {string} params.idmToken - Token do IDM
     * @description Retorna o token de acesso do IDM se o token informado for válido
     */
    async checkIDMToken(params) {
      try {
        const recaptchaToken = await recaptcha.getToken("login");

        return $http({
          url: "/check",
          method: "post",
          headers: {
            "x-captcha-token": recaptchaToken,
            "ping-flow": "true"
          },
          data: {
            clientId: IDM_CLIENT_ID,
            accessToken: params.idmToken
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
}
