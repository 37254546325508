<template>
  <div>
    <header class="mdn-Menu">
      <!-- Top -->
      <div
        class="mdn-Menu-top"
        :class="{
          'mdn-Menu-top--contract': showContract
        }"
      >
        <div class="mdn-Container">
          <nav class="mdn-Menu-top-content">
            <ul class="mdn-Menu-top-list mdn-Menu-top-list-left" style="">
              <router-link
                tag="li"
                to="/empresas/contratos"
                class="mdn-Menu-top-list-item mdn-Menu-top-list-item-location mdn-Menu-top-list-item-location--logged"
                v-if="showContract"
              >
                <p class="mdn-Text">
                  Contrato: {{ contract.operatorCode }}/{{
                    contract.contractNumber
                  }}
                </p>
                <i
                  class="mdn-Menu-top-list-item-icon mdn-Icon-baixo mdn-Icon--md"
                ></i>
              </router-link>
            </ul>
          </nav>
        </div>
      </div>

      <!-- Menu -->
      <div class="mdn-Menu-container mdn-Container">
        <nav class="mdn-Menu-content">
          <div class="mdn-Menu-content-left">
            <router-link to="/empresas" class="mdn-Menu-logo-wrapper">
              <img
                class="mdn-Menu-logo"
                alt="logo da minha claro empresas"
                style="cursor: pointer"
                data-gtm-event-category="minha-claro-res:geral"
                data-gtm-event-action="clique:menu-superior"
                data-gtm-event-label="logo"
                srcset="https://mondrian.claro.com.br/brands/horizontal/inverse/minha-claro-empresas.svg"
                Ï
              />
            </router-link>
          </div>
          <div class="mdn-Menu-content-right">
            <ul class="mdn-Menu-list">
              <li class="mdn-Menu-list-item mdn-Menu-list-item-search">
                <div class="mdn-Menu-list-item-link">
                  <div class="mdn-Menu-subMenu-back">
                    <i class="mdn-Icon-esquerda mdn-Icon--md"></i>
                    <p class="mdn-Heading">Voltar</p>
                  </div>
                  <a
                    data-gtm-event-category="minha-claro-res:geral"
                    data-gtm-event-action="clique:menu-superior"
                    data-gtm-event-label="sair"
                    role="button"
                    tabindex="0"
                    title="sair da minha claro empresas"
                    class="mdn-Text gtm-element-event exit"
                    href="#"
                    @click.prevent="logout"
                  >
                    <i class="mdn-Icon-sair mdn-Icon--md"></i>
                    <span
                      class="mdn-Menu-list-item mdn-Menu-list-item-search-label"
                    >
                      Sair
                    </span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
    <div
      id="main-content"
      class="mdn-Container mdn-u-paddingTop--sm mdn-u-paddingBottom--sm container"
    >
      <router-view />
      <mcr-pj-protocol v-if="showContract" />
    </div>
    <footer class="mdn-Footer mdn-Footer--simple mdn-Footer--signature">
      <div class="mdn-Container">
        <div class="mdn-Footer-content">
          <div class="mdn-Footer-disclaimer">
            <p class="mdn-Text mdn-Text--caption mdn-Text--inverse">
              <a
                class="mdn-Footer-disclaimer-link"
                href="https://www.claro.com.br/privacidade/politica-de-privacidade"
                target="_blank"
                >Política de Privacidade</a
              ><a
                class="mdn-Footer-disclaimer-link"
                href="https://www.claro.com.br/privacidade"
                target="_blank"
                >Portal de Privacidade</a
              >
            </p>
            <p class="mdn-Text mdn-Text--caption mdn-Text--inverse">
              <strong
                >© Claro - CNPJ: 40.432.544/0001- 47 <br />
                Rua Henri Dunant, 780 - São Paulo - SP</strong
              >
            </p>
          </div>
          <img
            alt="logo da claro"
            class="mdn-Footer-logo no-background"
            src="https://mondrian.claro.com.br/brands/nosvg/assinatura-claro.png"
          />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import McrPjProtocol from "../components/McrPjProtocol.vue";

import { clarity } from "../pages/pj/utils/clarity";

export default {
  components: {
    McrPjProtocol
  },
  computed: {
    ...mapState("PJ", ["contract"]),
    showContract() {
      return this.contract && this.$route.path !== "/empresas/contratos";
    }
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
    getProtocol() {}
  },
  mounted() {
    document.body.style.backgroundColor = "var(--theme-color-neutral-light-bg)";
  },
  beforeMount() {
    clarity.addScript();
  },
  beforeDestroy() {
    document.body.style.backgroundColor = "";
    clarity.removeScript();
  }
};
</script>

<style lang="scss" scoped>
.mdn-Menu-top {
  display: flex;

  $root: &;

  &:not(#{$root}--contract) {
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    height: 3rem;
  }
}

.container {
  min-height: 70dvh;
}
</style>
