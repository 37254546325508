<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "empty-layout"
};
</script>
