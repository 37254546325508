import lscache from "lscache";

const DEFAULT_CACHE_TIME = 5;

export const createCacheStorage = storageKey => {
  if (storageKey) {
    lscache.setBucket(storageKey);
  }

  return {
    get(key) {
      return lscache.get(key);
    },
    set: (key, value, time = DEFAULT_CACHE_TIME) => {
      lscache.set(key, value, time);
    },
    remove(key) {
      lscache.remove(key);
    },
    clear() {
      lscache.flush();
    }
  };
};
