export default [
  {
    path: "/atendimento-acessivel",
    name: "affortable-service",
    meta: {
      requiresAuth: true,
      squad: "Atendimento Acessível",
      title: "Atendimento Acessível - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "accessibility" */
        "../../pages/home/Accessibility.vue"
      )
  },
  {
    path: "/atendimento/mudanca-de-endereco",
    name: "address-change",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Mudança de Endereço - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-address-change" */
        "../../pages/attendance/AddressChange.vue"
      )
  },
  {
    path: "/atendimento/fale-conosco",
    name: "contact-us",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Fale Conosco - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-contact-us" */
        "../../pages/attendance/ContactUs.vue"
      )
  },
  {
    path: "/atendimento/historico-de-atendimento",
    name: "customer-service-history",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Histórico de atendimento - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-customer-service-history" */
        "../../pages/attendance/CustomerServiceHistory.vue"
      )
  },
  {
    path: "/atendimento/servicos",
    name: "cancellation-request",
    meta: {
      requiresAuth: true,
      squad: "Atendimento",
      title: "Cancelamento - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "cancellation-request" */
        "../../pages/attendance/CancellationRequest.vue"
      )
  },
  {
    path: "/atendimento/acompanhamento-solicitacoes",
    name: "order-tracking",
    meta: {
      requiresAuth: true,
      isEnabledForNet: true,
      isEnabledForCtv: true,
      squad: "Atendimento",
      title: "Acompanhamento de Solicitações - Minha Claro Residencial",
    },
    component: () =>
      import(
        /* webpackChunkName: "order-tracking" */ "../../pages/attendance/OrderTracking.vue"
      ),
  },
  {
    path: "/atendimento/fale-com-a-claro",
    name: "contact-us-private",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Fale com a Claro - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-contact-us-private" */
        "../../pages/attendance/ContactUsPrivate.vue"
      )
  },
  {
    path: "/atendimento/ouvidoria",
    name: "ombudsman",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Ouvidoria - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-ombudsman" */
        "../../pages/attendance/Ombudsman.vue"
      )
  },
  {
    path: "/atendimento/wifi-na-cidade",
    name: "device-management",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Wi-fi na cidade - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-device-management" */
        "../../pages/attendance/DeviceManagement.vue"
      )
  },
  {
    path: "/atendimento/consumo-de-franquia",
    name: "franchise-consumption",
    meta: {
      requiresAuth: true,
      squad: "Atendimento",
      title: "Consumo de Franquia - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "franchise-consumption" */
        "../../pages/attendance/FranchiseConsumption.vue"
      )
  },
  {
    path: "/atendimento/denuncie-pirataria",
    name: "report-piracy",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Denuncie Pirataria - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-report-piracy" */
        "../../pages/attendance/ReportPiracy.vue"
      )
  },
  {
    path: "/atendimento/fale-conosco-prospect",
    name: "contact-us-prospect",
    meta: {
      isPublic: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Fale Conosco - Minha Claro Residencial"
    },

    component: () =>
      import(
        /* webpackChunkName: "migration-contact-us-prospect" */
        "../../pages/attendance/ContactUsProspect.vue"
      )
  },
  {
    path: "/atendimento/portabilidade",
    name: "portability",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Atendimento",
      title: "Portabilidade - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-portability" */
        "../../pages/attendance/Portability.vue"
      )
  },
  {
    path: "/atendimento/troca-de-titularidade",
    name: "ownership-transfer",
    meta: {
      requiresAuth: true,
      squad: "Atendimento",
      title: "Troca de Titularidade - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "ownership-transfer" */
        "../../pages/attendance/OwnershipTransfer.vue"
      )
  }
];
