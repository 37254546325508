import Cookies from "js-cookie";

export default {
  namespaced: true,
  state: () => ({
    isPME: false,
    token: null,
    contract: null,
    document: null,
    protocol: ""
  }),
  actions: {},
  getters: {
    email(state) {
      try {
        const emails = state.contract.subscriber.contact.emails;

        if (emails.length === 0) {
          return "";
        }

        return emails[0].address;
      } catch (error) {
        return "";
      }
    }
  },
  mutations: {
    SET_IS_PME(state, payload) {
      state.isPME = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_CONTRACT(state, payload) {
      state.contract = payload;

      const contractNumber = `${payload.operatorCode}${payload.contractNumber}`;

      Cookies.set("PJ_CONTRACT_NUMBER", contractNumber);
    },
    SET_DOCUMENT(state, payload) {
      state.document = payload;
    },
    SET_PROTOCOL(state, payload) {
      state.protocol = payload;
    }
  }
};
