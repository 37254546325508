const SESSION_STORAGE_KEY = "diagnosticSession";

const getDiagnosticParams = query => {
  const {
    diagnosticCode,
    diagnosticType,
    flowType,
    issueName,
    issuePriceFormated,
    reason,
    selectedProduct
  } = query;

  return {
    diagnosticCode,
    diagnosticType,
    flowType,
    issueName,
    issuePriceFormated,
    reason,
    selectedProduct
  };
};

const sendToStorage = (storage, key, params) => {
  storage.setItem(key, JSON.stringify(params));
};

const diagnosticHelper = async route => {
  if (!route.query.diagnosticCode) {
    return;
  }

  const params = getDiagnosticParams(route.query);

  sendToStorage(window.sessionStorage, SESSION_STORAGE_KEY, params);
};

export default diagnosticHelper;
